import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';

// const handleKeyDown = (e) => {
//   if (
//     (e.ctrlKey || e.metaKey) && 
//     (e.shiftKey && e.key === 'C') ||
//     (e.key === 'F12') ||
//     (e.key === 'F12' && e.code === 'F12')
//   ) {
//     e.preventDefault();
//     console.log("Developer tools are disabled");
//   }
// };

// const handleContextMenu = (e) => {
//   e.preventDefault();
//   console.log("Right-click is disabled");
// };

// const addEventListeners = () => {
//   window.addEventListener('keydown', handleKeyDown);
//   window.addEventListener('contextmenu', handleContextMenu);
// };

// const removeEventListeners = () => {
//   window.removeEventListener('keydown', handleKeyDown);
//   window.removeEventListener('contextmenu', handleContextMenu);
// };

const root = ReactDOM.createRoot(document.getElementById('root'));

// addEventListeners();

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Cleanup event listeners when the c
