import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../env";
import logo from "../assets/login-image.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  const loginApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(`${API_URL}/user/login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.status == 1) {
          localStorage.setItem("question_bank_token", result.token);
          Navigate("/Home");
          // toast.success("Login Successful")
        } else if (result.status == "VAL_ERR") {
          toast.error(result.Backend_Error);
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginApi();
    }
  };

  return (
    <>
      <div
        className="container"
        style={{
          height: "100vh",
        }}
      >
        <Toaster position="top-center" reverseOrder={false} />
        <div className="row justify-content center h-100 ">
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center ">
            <img src={logo} className="w-75 mx-auto mx-auto" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column mq">
            <h1>LOGIN</h1>
            <div className="pt-3">
              <input
                placeholder="Email Address"
                type="text"
                className="w-100 p-3 "
                style={{ border: "1px solid #E5E5E5" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div className="pt-3">
              <input
                placeholder="Password"
                type="password"
                className="w-100 p-3 "
                style={{ border: "1px solid #E5E5E5" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              ></input>
            </div>
            <div className="pt-3">
              <button
                onClick={loginApi}
                className="nextButtonClass w-100 p-3 mx-auto text-center"
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
