import React, { useEffect, useState } from "react";
import { BiBookBookmark } from "react-icons/bi"
import { MdOutlineDateRange } from "react-icons/md"
import { useNavigate } from "react-router-dom";
import { Loader } from "../common/Loader";

const HistoryCard = ({ isLoading, questionPaper }) => {
  const Navigate = useNavigate();

  return (
    <>
      {
        isLoading ? <Loader show={isLoading} /> :
          <>
            {
              questionPaper.length > 0 ?
                questionPaper.map((value, index) => (
                  <div className="card p-3 mt-3">
                    <h6 style={{ color: "#000", fontFamily: "Archivo", fontWeight: 600, fontStyle: "normal", fontSize: "1rem", textTransform: "capitalize" }} >{value.Question_paper_name}</h6>
                    <span style={{ color: "#7e7e7e", fontFamily: "Archivo", fontWeight: 400, fontStyle: "normal", fontSize: "0.85rem" }}> <BiBookBookmark /> {value.course_name}</span>
                    <div className="d-flex gap-5 align-items-center" >
                      <p style={{ color: "#7e7e7e", fontFamily: "Archivo", fontWeight: 400, fontStyle: "normal", fontSize: "0.85rem" }}>Questions : {value.Questions}</p>
                      <p style={{ color: "#3774a5", fontFamily: "Archivo", fontWeight: 400, fontStyle: "normal", fontSize: "0.85rem" }} ><MdOutlineDateRange style={{ verticalAlign: "middle" }} /> {value.created_date.split('T')[0]}</p>
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "end"
                    }}>
                      <button onClick={() => Navigate(`/ViewPaperDetails/${value.Question_paper_id}`)} className="py-2 p-4" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff", fontSize: "0.85rem", fontStyle: "normal", fontWeight: 400, backgroundColor: "rgba(85,101,206,255)", }} >View Details</button>
                    </div>
                  </div>
                ))
                : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>No question paper created on this date </h4></>
            }
          </>
      }
    </>
  );
};

export default HistoryCard;