import React from 'react';
import { useNavigate, } from 'react-router-dom';
import { Loader } from '../common/Loader';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

const CourseCard = ({ topic, isLoading, filter, remove }) => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [id, setid] = useState('');

  console.log("filter" ,filter)

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className='outline' style={{ display: "flex", height: "30vh", flexDirection: "column", justifyContent: "center" }}>
          <div >
            <div className=''>
              <h1 className='sets' style={{ border: "0" }}>Remove topic</h1>
              <h1 className='action'>The action cannot be reversed</h1>
            </div>
            <div className='d-flex justify-content-center'>
              <button className='cancel ' onClick={handleClose}>Cancel</button>
            </div>
            <div className='d-flex justify-content-center mt-2'>
              <button className='ahead' onClick={() => { remove(id); handleClose() }}>Process Ahead</button>
            </div>
          </div>
        </div>
      </Modal>
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <>
          {filter.length > 0 ? (
            // Render filtered topics
            filter.map((value, index) => (
              <div className="card p-3 mt-3" key={index}>
                <h6
                  style={{
                    color: '#000',
                    fontFamily: 'Archivo',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    fontSize: '1rem',
                    textTransform: "capitalize"
                  }}
                >
                  {value.topic_name}
                </h6>
                <p
                  style={{
                    color: '#3774A5',
                    fontFamily: 'Archivo',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontSize: '0.85rem',
                  }}
                >
                  Questions: {value.fillup_no_of_questions + value.long_no_of_questions + value.mcq_no_of_questions + value.short_no_of_questions

}
                </p>
                <div className='row col-12'>
                  <div className='col-6'>
                    <button
                      onClick={() => {
                        Navigate(`/Quest/${value.topic_id}/${"topic"}`);
                      }}
                      className="py-2 w-100 "
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "5px",
                        color: "#fff",
                        fontSize: "0.85rem",
                        fontStyle: "normal",
                        fontWeight: 400,
                        backgroundColor: "rgba(85,101,206,255)"
                      }}
                    >
                      View Questions
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      onClick={() => {
                        setid(value.topic_id)
                        handleShow()
                      }}
                      className="py-2 w-100"
                      style={{
                        border: "1px solid #fff",
                        borderRadius: '5px',
                        color: '#fff',
                        fontSize: '0.85rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        backgroundColor: "#AD1111",
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              {topic.length > 0 ? (
                topic.map((value, index) => (
                  <div className="card p-3 mt-3" key={index}>
                    <h6
                      style={{
                        color: '#000',
                        fontFamily: 'Archivo',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        fontSize: '1rem',
                        textTransform: "capitalize"
                      }}
                    >
                      {value.topic_name}
                    </h6>
                    <p
                      style={{
                        color: '#3774A5',
                        fontFamily: 'Archivo',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontSize: '0.85rem',
                      }}
                    >
                      Questions: {value.no_of_questions}
                    </p>
                    <div className='row col-12'>
                      <div className='col-6'>
                        <button
                          onClick={() => {
                            Navigate(`/Quest/${value.topic_id}/${"topic"}`);
                          }}
                          className="py-1 w-100"
                          style={{
                            border: "1px solid #fff",
                            borderRadius: "5px",
                            color: "#fff",
                            fontSize: "0.85rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            backgroundColor: "rgba(85,101,206,255)"
                          }}
                        >
                          View Questions
                        </button>
                      </div>
                      <div className='col-6'>
                        <button
                          onClick={() => {
                            setid(value.topic_id)
                            handleShow()
                          }}
                          className="py-1 w-100"
                          style={{
                            borderRadius: '5px',
                            color: '#fff',
                            fontSize: '0.85rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            backgroundColor: "#AD1111",
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h4 className="text-center mt-5" style={{ color: 'red', fontWeight: '600' }}>
                  No Topics added <br /> in this Subject
                </h4>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CourseCard;
