import React from 'react';
import Navbar from '../home/Navbar';
import Bottom from '../home/Bottom';
import { API_URL } from '../../env';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Loader } from '../common/Loader';

const Board = () => {

    const [showboard, setShowboard] = useState([]);
    const Navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const options = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("question_bank_token")}`
        );

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${API_URL}/show/all/board`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setIsLoading(false);
                if (result.status == 1) {
                    setShowboard(result.allBoards);
                } else if (result.status == 111) {
                    localStorage.removeItem("question_bank_token");
                    Navigate('/')
                }
            })
            .catch((error) => console.log("error", error));
    };

    const addBoard = async() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "board_name": inputValue
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/add/board`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    processInput();
                    options()
                    setInputValue('')
                }
            })
            .catch(error => console.log('error', error));
    }

    const [isInputBoxVisible, setIsInputBoxVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const toggleInputBox = () => {
        setIsInputBoxVisible(!isInputBoxVisible);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const processInput = () => {
        toggleInputBox();
    };

    useEffect(() => {
        options();
    }, [])

    return (
        <>
            <div style={{ height: "100vh", width: "100vw" }} >
                <div style={{ height: "8%" }} >
                    <Navbar />
                </div>
                <div style={{ height: "84%", overflowY: "auto" }} >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='d-flex flex-column'>
                                    <h1 className='mt-4 homeDataMedium ' style={{ fontWeight: "700" }}>Showing Board ({showboard.length})</h1>
                                    <button className="btn px-3 ms-auto" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff", backgroundColor: "rgba(85,101,206,255)", }} onClick={toggleInputBox} >
                                        Add Board
                                    </button>
                                </div>

                                {isInputBoxVisible && (
                                    <div className='mt-4'>
                                        <input
                                            type='text'
                                            className="w-100 p-2"
                                            placeholder='Board '
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                        />
                                        <button className="py-2 p-4 mt-3"
                                            style={{
                                                border: "1px solid #fff",
                                                borderRadius: "5px",
                                                color: "#fff",
                                                fontSize: "0.85rem",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                backgroundColor: "rgba(85,101,206,255)"
                                            }} onClick={() => addBoard()}>Add</button>
                                    </div>
                                )}
                                {
                                    isLoading ? <Loader show={isLoading} /> : <>
                                        <div className='mt-2'>
                                            {
                                                showboard.map((value, index) => (
                                                    <>
                                                        <NavLink to={`/Subcard/${0}/${value.id}`} style={{ textDecoration: "none" }}>
                                                            <div className='card p-1 text-center mt-2 py-3' style={{ border: "1px solid #DFDFDF", borderRadius: "5px" }}>
                                                                <h1 className='mediumCardHeading mb-0 '>{value.board_name}</h1>
                                                            </div>
                                                        </NavLink>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "8%" }}>
                    <Bottom />
                </div>
            </div>
        </>
    )
}

export default Board;
