import { RotatingLines } from 'react-loader-spinner'

export const Loader = ({ show }) => {
    return (
        <>
            {show && (
                <div class="loading-overlay">
                    <span class="fas fa-spinner fa-3x fa-spin">
                        <RotatingLines
                            strokeColor="skyblue"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={true}
                        />
                    </span>
                </div>
            )}
        </>
    );
};