import React, { useEffect, useState } from 'react'
import Select from 'react-select';

const SelectSubject = ({ onSubject, showSubject }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (onSubject) {
            onSubject(selectedOption?.value || null)
        }
    }, [selectedOption, onSubject])

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h1 className='createPaperHeading mt-3' >Select Subject</h1>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={showSubject.map((res) => ({
                                value: res.subject_id,
                                label: res.subject_name
                            }))}
                            styles={{ overflowY: "scroll", }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectSubject
