import React from 'react'
import { useNavigate } from "react-router-dom";
import sort from "../assets/sort.svg";
import Navbar from '../home/Navbar'
import plus from "../assets/plus.svg";
import { useState } from "react";
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../common/Loader';
import { API_URL } from '../../env';
import Bottom from '../home/Bottom';
import { Offcanvas } from 'react-bootstrap';
import Select from 'react-select';
import { ImSearch } from 'react-icons/im'
import Modal from 'react-bootstrap/Modal';

const CouTopic = () => {
  
  const Navigate = useNavigate();
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [id, setid] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [topic_name, setTopics_name] = useState("");
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [isInputBoxVisible, setIsInputBoxVisible] = useState(false);

  const subData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`${API_URL}/show/all/topic`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        if (result.status == 1) {
          setTopics(result.topic_details);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch(error => console.log('error', error));
  }

  const options = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/show/all/subjects`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setSubjects(result.all_subjects)
        if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch(error => console.log('error', error));
  }

  const addTopic = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "topic_name": topic_name,
      "subject_id": selectedOption
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/add/topic`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          handleClose();
          // toast.success(result.message);
          subData();
          setTopics_name("");
          setSelectedOption(null);
        } else if (result.status === "VAL_ERR") {
          toast.error(result.Backend_Error);
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    const filteredResults = value
      ? topics.filter((t) =>
        t.topic_name.toLowerCase().includes(value.toLowerCase())
      )
      : topics;
    setFilteredTopics(filteredResults);
  };

  const remove = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/remove/topic/by/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          toast.success(result.message);
          subData()
        }
      })
      .catch(error => console.log('error', error));
  }

  const topicbySorting = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`${API_URL}/show/topics/by/alphabets`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        if (result.status == 1) {
          setTopics(result.topic_details);
        }

      })
      .catch(error => console.log('error', error));
  }

  const toggleInputBox = () => {
    setIsInputBoxVisible(!isInputBoxVisible);
  };

  useEffect(() => {
    subData();
  }, [])
  return (
    <>
      <div style={{ height: "100vh", width: "100%" }} >
        <Toaster />
        <div style={{ height: "16%" }} >
          <div className='container h-100 '>
            <div className='row'>
              <div className='' >
                <Navbar />
                <div className='col-lg-12 col-sm-12'>

                  <div className="row align-items-center mt-4">
                    <div className="col-6 d-flex align-items-center mt-3">
                      <span className='ms-2' style={{ fontFamily: "Archivo", color: "#000", fontWeight: 500, fontStyle: "normal", fontSize: "1.5rem" }} >All Topics</span>
                    </div>
                    <div className="col-6 text-end d-flex justify-content-end align-items-center gap-3" >
                      <div className="p-1" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                        <ImSearch className='m-1' onClick={toggleInputBox} />
                      </div>
                      <div className="p-1" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                        <img src={sort} alt="sort" onClick={() => topicbySorting()} />
                      </div>
                      <div className="p-1 px-2" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                        <img src={plus} alt="plus" onClick={() => { handleShow(); options() }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3 example" style={{ height: "", overflowY: "auto" }} >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "76%", overflowY: "auto" }} >
          <div className='container'>
            <div className='row'>
              {isInputBoxVisible && (
                <div className='mt-4'>
                  <input
                    value={searchTerm}
                    onChange={handleChange}
                    type="text"
                    className="w-100 p-2"
                    placeholder="Topic name"
                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                  />
                </div>
              )}
              <div className='col-lg-12'>
                <>
                  {isLoading ? (
                    <Loader show={isLoading} />
                  ) : (
                    <>
                      {filteredTopics.length > 0 ? (
                        // Render filtered topics
                        filteredTopics.map((value, index) => (
                          <div className='card mt-3 p-0 mt-2 ' style={{ border: '1px solid black' }}>
                            <div className='card-body p-0  p-3'>
                              <h1
                                style={{
                                  color: "#000",
                                  fontFamily: "Archivo",
                                  fontWeight: 400,
                                  fontStyle: "normal",
                                  fontSize: "24px",
                                  textTransform: "capitalize"
                                }}
                              > {value.topic_name}</h1>

                              <div style={{ lineHeight: '' }}>
                                <p
                                  style={{
                                    margin: '0%',
                                    color: "#3774A5",
                                    fontFamily: "Archivo",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                  }}
                                >
                                  Number of Questions: {value.no_of_questions}
                                </p>
                              </div>
                              <div className='row col-12'>
                                <div className='col-6'>
                                  <button
                                    className="py-2 mt-3 w-100 "
                                    onClick={() => Navigate(`/Quest/${value.topic_id}/${"topic"}`)}
                                    style={{
                                      border: "1px solid #fff",
                                      borderRadius: "5px",
                                      color: "#fff",
                                      fontSize: "0.85rem",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      backgroundColor: "rgba(85,101,206,255)",
                                    }}
                                  >
                                    View Questions
                                  </button>
                                </div>
                                <div className='col-6'>
                                  <button
                                    className="py-2 mt-3 w-100  "
                                    onClick={() => {
                                      setid(value.topic_id)
                                      handleShow1()
                                    }}
                                    style={{
                                      border: "1px solid #fff",
                                      borderRadius: "5px",
                                      color: "#fff",
                                      fontSize: "0.85rem",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      backgroundColor: "#AD1111",
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <>
                          {topics.length > 0 ? (
                            topics.map((value, index) => (
                              <div className='card mt-3 p-0 mt-2 ' style={{ border: '1px solid black' }}>
                                <div className='card-body p-0  p-3'>
                                  <h1
                                    style={{
                                      color: "#000",
                                      fontFamily: "Archivo",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      fontSize: "24px",
                                      textTransform: "capitalize"
                                    }}
                                  > {value.topic_name}</h1>

                                  <div style={{ lineHeight: '' }}>
                                    <p
                                      style={{
                                        margin: '0%',
                                        color: "#3774A5",
                                        fontFamily: "Archivo",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Number of Questions: {value.no_of_questions}
                                    </p>
                                  </div>
                                  <div className='row col-12'>
                                    <div className='col-6'>
                                      <button
                                        className="py-2  mt-3 w-100  "
                                        onClick={() => Navigate(`/Quest/${value.topic_id}/${"topic"}`)}
                                        style={{
                                          border: "1px solid #fff",
                                          borderRadius: "5px",
                                          color: "#fff",
                                          fontSize: "0.85rem",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          backgroundColor: "rgba(85,101,206,255)",
                                        }}
                                      >
                                        View Questions
                                      </button>
                                    </div>
                                    <div className='col-6'>
                                      <button
                                        className="py-2  mt-3 w-100  "
                                        onClick={() => {
                                          setid(value.topic_id)
                                          handleShow1()
                                        }}
                                        style={{
                                          border: "1px solid #fff",
                                          borderRadius: "5px",
                                          color: "#fff",
                                          fontSize: "0.85rem",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          backgroundColor: "#AD1111",
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <h4 className="text-center mt-5" style={{ color: 'red', fontWeight: '600' }}>
                              No Topics added <br /> in this Subject
                            </h4>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
              <Offcanvas
                show={show}
                onHide={handleClose}
                placement="bottom"
                style={{ height: "60%" }}
              >
                <Offcanvas.Header>
                  <Offcanvas.Title
                    className="d-flex justify-content-center mx-auto"
                    onClick={handleClose}
                  >
                    <div
                      style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                    ></div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <h1
                    className="text-center"
                    style={{
                      color: "#000",
                      fontSize: "1.25rem",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Add Topic
                  </h1>
                  <div className="d-flex flex-column justify-content-between mt-3 " style={{ height: "85%" }} >
                    <div className="d-flex flex-column gap-3">
                      <input value={topic_name} onChange={(e) => setTopics_name(e.target.value)} placeholder='Section Name' type='text' className='w-100 p-2 ' style={{ border: '1px solid #E5E5E5', borderRadius: "5px" }} />

                      <Select
                        className="mt-2"
                        styles={{ border: "1px solid #E5E5E5", overflowY: "scroll", }}
                        defaultValue={selectedOption}
                        onChange={(selectedOption) => {
                          setSelectedOption(selectedOption.value);
                        }}
                        options={subjects.map((res) => ({
                          value: res.id,
                          label: res.sub_name
                        }))}
                        placeholder="Subject"
                      />
                    </div>
                    <div>
                      <button
                        onClick={addTopic}
                        className="w-100 py-2 border-0"
                        style={{
                          background: "#EDF7FF",
                          borderRadius: "5px",
                          color: "#3774A5",
                          fontSize: "0.875rem",
                          fontStyle: "normal",
                          fontWeight: 500,
                        }}
                      >
                        Add section
                      </button>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              <Modal
                show={show1}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
                centered
              >
                <div className='outline' style={{ display: "flex", height: "30vh", flexDirection: "column", justifyContent: "center" }}>
                  <div >
                    <div className=''>
                      <h1 className='sets' style={{ border: "0" }}>Remove topic</h1>
                      <h1 className='action'>The action cannot be reversed</h1>
                    </div>
                    <div className='d-flex justify-content-center'>
                      <button className='cancel ' onClick={handleClose1}>Cancel</button>
                    </div>
                    <div className='d-flex justify-content-center mt-2'>
                      <button className='ahead' onClick={() => { remove(id); handleClose1() }}>Process Ahead</button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <div style={{ height: "8%" }} >
          <Bottom />
        </div>
      </div>
    </>
  )
}

export default CouTopic
