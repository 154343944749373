import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backButton from "../assets/backButton.svg";
import sort from "../assets/sort.svg";
import plus from "../assets/plus.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import toast from "react-hot-toast";
import { Loader } from "../common/Loader";
import { API_URL } from '../../env'

const Subjects = () => {
  const [subject, setSubject] = useState([]);
  const [subjectName, setSubjectName] = useState("");
  const { id, course_name } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();

  const showSubjects = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsLoading(true)
    fetch(
      `${API_URL}/show/subject/by/courseid/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result.status == 1) {
          setSubject(result.subjectDetails);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log("error", error));
  };

  const addSubject = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );

    var raw = JSON.stringify({
      sub_name: subjectName,
      course_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/add/subject`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          // toast.success(result.message);
          setSubjectName("");
          handleClose();
          showSubjects();
        } else if (result.status == "VAL_ERR") {
          toast.error(result.Backend_Error)
        } else {
          console.log(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const showSubjectsBySort = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/show/subject/by/alphabets/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          setSubject(result.subjectDetails);
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    showSubjects();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="container ">
        <div className="row mt-2">

          <div className="col-2">
            <img
              onClick={() => Navigate(-1)}
              src={backButton}
              alt="back"
              style={{}}
            />
          </div>
          <div className="col-8">
            <h2 className="text-center">{course_name}</h2>
          </div>

        </div>
      </div>

      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}></div>
      </div> */}
      <div className="container h-100">
        <div className="row">
          <div className="col-lg-12 col-sm-12 ">
            <div className="row align-items-center mt-3 ">
              <div className="col-6">
                <h1
                  style={{
                    fontFamily: "Archivo",
                    color: "#000",
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "1.5rem",
                  }}
                >
                  Subjects
                </h1>
              </div>
              <div className="col-6 text-end d-flex justify-content-end align-items-center gap-3">
                <div
                  className="p-1"
                  style={{ border: "1px solid #000", borderRadius: "5px" }}
                >
                  <img src={sort} alt="sort" onClick={() => showSubjectsBySort()} />
                </div>
                <div
                  className="p-1 px-2"
                  style={{ border: "1px solid #000", borderRadius: "5px" }}
                >
                  <img src={plus} alt="plus" onClick={handleShow} />
                </div>
              </div>
            </div>
            <div
              className="col-12 mt-3 example"
              style={{ height: "", overflowY: "auto" }}
            ></div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "30%" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            className="d-flex justify-content-center mx-auto"
            onClick={handleClose}
          >
            <div
              style={{ width: "50px", height: "2px", background: "#E1E1E1" }}
            ></div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1
            className="text-center"
            style={{
              color: "#000",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Add Subject
          </h1>
          <div className="d-flex flex-column justify-content-between h-75">
            <div className="d-flex flex-column">
              <input
                type="text"
                className="w-100 p-2"
                placeholder="Subject Name"
                style={{ border: "1px solid #E5E5E5", borderRadius: "5px" }}
                value={subjectName}
                onChange={(e) => setSubjectName(e.target.value)}
              />
            </div>

            <div>
              <button
                onClick={() => addSubject()}
                className="w-100 py-2 border-0"
                style={{
                  background: "#EDF7FF",
                  borderRadius: "5px",
                  color: "#3774A5",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Add Subject
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="container">
        <div className="row">
            {
              isLoading ? <Loader show={isLoading} /> :
                <div
                  className="example"
                  style={{ height: "83vh", overflowY: "scroll" }}
                >
                  {subject.length > 0 ?
                    subject.map((res, index) => {
                      return (
          <div className="col-lg-6 col-md-6 col-sm-12">
                        <div
                          className="card mt-3 p-0  "
                          style={{ border: "1px solid black" }}
                        >
                          <div className="card-body p-0  p-3">
                            <h1
                              style={{
                                color: "#000",
                                fontFamily: "Archivo",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontSize: "24px",
                                textTransform: "capitalize"
                              }}
                            >
                              {" "}
                              {res.subject_name}
                            </h1>

                            <div style={{ lineHeight: "" }}>
                              <p
                                style={{
                                  margin: "0%",
                                  color: "#3774A5",
                                  fontFamily: "Archivo",
                                  fontWeight: 400,
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                }}
                              >
                                Number of Topics: {res.no_of_topics}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end"
                              }}
                            >
                              <button
                                className="py-2 mt-3 p-4"
                                onClick={() => { Navigate(`/Topics/${res.subject_id}`); sessionStorage.setItem("subject_name", res.subject_name); localStorage.setItem("subject_name", res.subject_name) }}
                                style={{
                                  border: "1px solid #fff",
                                  borderRadius: "5px",
                                  color: "#fff",
                                  fontSize: "0.85rem",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  backgroundColor: "rgba(85,101,206,255)"
                                }}
                              >
                                View Topics
                              </button>
                            </div>
                          </div>
                        </div>
          </div>
                      );
                    }) : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>No Subjects added <br />in this Course</h4></>
                  }
                </div>
            }
        </div>
      </div>
    </div>
  );
};

export default Subjects;
