import React, { useState } from 'react'
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import TabsMcq from './TabsMcq';
import TabsBlanks from './TabsBlanks';
import TabsShort from './TabsShort';
import TabsLong from './TabsLong';

const Questions = () => {
  const Navigate = useNavigate();
  const { status } = useParams();
  const [tabs, setTabs] = useState(1);

  return (
    <div style={{ height: "100vh", width: "100vw" }} >
      <div className='d-flex align-items-center justify-content-between px-3' style={{ height: "8%" }}   >
        <IoArrowBackSharp onClick={() => Navigate(-1)} style={{ fontSize: "1.2rem" }} />
        <div >
          <span style={{ fontSize: "1.2rem" }} className='mb-0'>Question</span>
        </div>
        <div></div>
      </div>
      <div className='' style={{ height: "92%", }} >
        <div className='' >
          <div className='d-flex align-items-center p-2 justify-content-between' >
            <div>
              <span className='pb-1 px-2' style={{ borderBottom: tabs == 1 ? "2px solid blue" : "none" }} onClick={() => setTabs(1)} >MCQ</span>
              <span className='pb-1 px-2' style={{ borderBottom: tabs == 2 ? "2px solid blue" : "none" }} onClick={() => setTabs(2)}>F/B</span>
              <span className='pb-1 px-2' style={{ borderBottom: tabs == 3 ? "2px solid blue" : "none" }} onClick={() => setTabs(3)}>SHORT</span>
              <span className='pb-1 px-2' style={{ borderBottom: tabs == 4 ? "2px solid blue" : "none" }} onClick={() => setTabs(4)}>LONG</span>
            </div>
          </div>
          <div>
            {
              tabs == 1 ?
                <>
                  <div className='container example' style={{ height: "80vh", overflowY: "auto" }} >
                    <div className='row' >
                      <div className='col-12' >
                        <TabsMcq status={status} />
                      </div>
                    </div>
                  </div>
                </> : <></>
            }
            {
              tabs == 2 ?
                <>
                  <div className='container' >
                    <div className='row' >
                      <div className='col-12' >
                        <TabsBlanks status={status} />
                      </div>
                    </div>
                  </div>
                </> : <></>
            }
            {
              tabs == 3 ?
                <>
                  <div className='container' >
                    <div className='row' >
                      <div className='col-12' >
                        <TabsShort status={status} />
                      </div>
                    </div>
                  </div>
                </> : <></>
            }
            {
              tabs == 4 ?
                <>
                  <div className='container' >
                    <div className='row' >
                      <div className='col-12' >
                        <TabsLong status={status} />
                      </div>
                    </div>
                  </div>
                </> : <></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Questions

