import React,{useState,useEffect} from "react";
import backButton from "../assets/backButton.svg";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {BsFiletypePdf} from "react-icons/bs"
import {MdOutlineModeEditOutline} from "react-icons/md"
import { API_URL } from "../../env";
import toast, { Toaster } from 'react-hot-toast';

import jsPDF from 'jspdf';


const generatePDF = (data) => {
  const doc = new jsPDF();
  const currentDate =  new Date();
  let dateString = currentDate.toLocaleDateString('en-GB'); 
  

// heading
  let pageWidth = doc.internal.pageSize.width;
  let textWidth = doc.getStringUnitWidth(data.questionPaperName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  let x = (pageWidth - textWidth) / 2;
  let y = 10; 
  doc.setFontSize(20).setFont('undefined' , 'bold').text(data.questionPaperName, x, y);

  
  // sections
  let startY = 25; 
  const gapHeight= 6; 
  const upperHeight = 1;

  Object.entries(data.sectionDetails).forEach(([key, value]) => {
    doc.setFontSize(15).setFont('undefined' , 'bold').setTextColor("black").text(5, startY, 
      `${key} (${value})`);

      if (value == "short") {
        startY += upperHeight
        data.short_ques.forEach((item , index)=>{
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,`Q${index+1}. ${item.question}` );
        })

      }

       else if(value == "long") {
        startY += upperHeight
        data.long_ques.forEach((item , index)=>{
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,`Q${index+1}. ${item.question}` );
        })

      }

       else if(value == "fillup") {
        startY += upperHeight
        data.fill_up_ques.forEach((item , index)=>{
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,`Q${index+1}. ${item.question}` );
        })
      
      } 
      // mcq
      else{
        startY += upperHeight
        data.mcq_ques.forEach((item , index)=>{

          // question
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,
            `Q${index+1}. ${item.question}` );

            // options
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,
            `(A). ${item.option1}` );
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,
            `(B). ${item.option2}` );
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,
            `(C). ${item.option3}` );
          startY += gapHeight
          doc.setFontSize(12).setFont('undefined' , 'normal').setTextColor("black").text(10, startY,
            `(D). ${item.option4}` );
          startY += gapHeight
        })
      }

      startY += 12; // Increment Y position for next item
});

  

  // downloading
  doc.save(`${data.questionPaperName}-${dateString}.pdf`);
};




const ViewPaperDetails = () => {
  const {id} = useParams();

  const [activeKey, setActiveKey] = useState(null);
  const Navigate = useNavigate();
  const [topics,setTopics] = useState([]);
  const [section,setSection] = useState([]);
  const [paperName, setPaperName] = useState("");
  const [totalQuestions, setTotalQuestions] = useState("");
  const [totalMarks, setTotalMarks] = useState("");


  const fetchHtmlContent = () => {

    try{
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/generate/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        
        if (result.status == 1) {
          console.log("fteched data successfully")
          console.log(result);
          generatePDF(result.question_paper_data)
        }

      })
      .catch((error) => console.log("error", error));
  } catch (e) {
    console.log(e);
  }


  };

  const details = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("question_bank_token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${API_URL}/show/question/paper/byid/${id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setPaperName(result.question_paper_details.que_paper_name);
            setTotalQuestions(result.question_paper_details.totalQuestions);
            setTotalMarks(result.question_paper_details.totalMarks);
            setTopics(result.question_paper_details.topicsData);
            setSection(result.question_paper_details.section_details);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };

//   const genratePdf = async()=>{
//     try {
//       var myHeaders = new Headers();
//       myHeaders.append(
//         "Authorization",
//         `Bearer ${localStorage.getItem("question_bank_token")}`
//       );

// var requestOptions = {
//   method: 'GET',
//   headers: myHeaders,
//   redirect: 'follow'
// };

// fetch(`https://paper.globalrns.com/generate/${id}`, requestOptions)
//   .then(response => response.json())
//   .then(result => console.log(result))
//   .catch(error => console.log('error', error));
//     } catch (error) {
//       console.log(error)
//     }
//   }



  const deletePaper = async(id)=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("question_bank_token")}`
      );
      myHeaders.append(
        "Contet-Type","application/json"
      );

var requestOptions = {
  method: 'DELETE',
  headers: myHeaders,
  redirect: 'follow'

};

fetch(`${API_URL}/delete/question/paper/byid/${id}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    
    
    if (result.status == 1) {
      toast.success("paper deleted Successfully")
      setTimeout(
        ()=>{
          
          Navigate(-1)
        } , 1000
      )
    }
    else{
      toast.error(result.message)
    }
  
  
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    details();
  }, []);
  return (
    <>
    <Toaster />
      <div style={{ height: "100vh" }}>
        <div className="container h-100">
          <div
            className="row py-2"
            style={{ borderBottom: "1px solid #CECECE", height: "15%" }}
          >
            <div className="col-2">
              <img
                onClick={() => Navigate(-1)}
                src={backButton}
                alt="back"
              />
            </div>
            <div className="col-8 text-center">
              <h1
                style={{
                  fontFamily: "Archivo",
                  color: "#000",
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontSize: "1.5rem",
                }}
              >
                Paper Details
              </h1>
            </div>
            <div className="col-2"></div>
            <div className="col-12 ">
              <p
                style={{
                  color: "#565656",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginBottom: "0",
                }}
              >
                Name of Paper
              </p>
              <h3
                style={{
                  color: "#000",
                  fontSize: "1.25rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {paperName}
              </h3>
            </div>
          </div>

          <div
          className="row p-2 example"
          style={{
            height: "80%",
            overflowY: "auto",
          }}
        >
          <div>
            <h1
              style={{
                fontSize: "0.875rem",
                fontWeight: 400,
                fontStyle: "normal",
              }}
            >
              Topics & Composition
            </h1>
          </div>

          <div className="col-12 pt-0 mt-0">
            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
              {topics.map((topic, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <span
                        style={{
                          fontFamily: "Archivo",
                          fontSize: "0.875rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                          color: "#000",
                        }}
                      >
                        {topic.topic_name}
                      </span>
                      <span
                        className="me-3"
                        style={{
                          fontFamily: "Archivo",
                          fontSize: "0.875rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                          color: "#FF2525",
                        }}
                      >
                        {topic.totalQues}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{ borderTop: "1px solid #E1E1E1" }}>
                    {topic.questionTypes.map((question, qIndex) => (
                      <div
                        key={qIndex}
                        className="d-flex align-items-center justify-content-between w-100"
                      >
                        <span
                          style={{
                            fontFamily: "Archivo",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          {question.type}
                        </span>
                        <span
                          className="me-3"
                          style={{
                            fontFamily: "Archivo",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            color: "#FF2525",
                          }}
                        >
                          {question.count}
                        </span>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>

          <div className="mt-3 pe-5">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                style={{
                  fontFamily: "Archivo",
                  fontSize: "1rem",
                  fontWeight: 600,
                  fontStyle: "normal",
                  color: "#565656",
                }}
              >
                Total Questions
              </h1>
              <h1
                style={{
                  fontFamily: "Archivo",
                  fontSize: "1rem",
                  fontWeight: 600,
                  fontStyle: "normal",
                  color: "#565656",
                }}
              >
                {totalQuestions}
              </h1>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h1
                style={{
                  fontFamily: "Archivo",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  fontStyle: "normal",
                  color: "#565656",
                }}
              >
                Total Marks
              </h1>
              <h1
                style={{
                  fontFamily: "Archivo",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  fontStyle: "normal",
                  color: "#565656",
                }}
              >
                {totalMarks}
              </h1>
            </div>
          </div>
          <hr className="mt-3" />

          <div className="mt-3">
            <h1 style={{color:"#565656",fontFamily:"Archivo",fontSize:"0.875rem",fontStyle:"normal",fontWeight:400}} >Section ( {section.length} )</h1>

            <div>
              {section.map((value, index) => (
                <div className="col-12 mt-3" key={index}>
                  <div className="card p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          color: "#242424",
                          fontSize: "1.5rem",
                          fontStyle: "normal",
                          fontWeight: 600,
                        }}
                      >
                        {value.section_name}
                      </span>
                      {/* <span
                        style={{
                          color: "#242424",
                          fontSize: "1.5rem",
                          fontStyle: "normal",
                          fontWeight: 600,
                        }}
                      >
                        Marks : {value.marks}
                      </span> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "1rem",
                          fontStyle: "normal",
                          fontWeight: 600,
                        }}
                      >
                        {value.questions_type}
                      </span>
                      {/* <span
                        style={{
                          color: "#565656",
                          fontSize: "1rem",
                          fontStyle: "normal",
                          fontWeight: 600,
                        }}
                      >
                        {value.fillInTheBlank}
                      </span> */}
                    </div>
                    {/* <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {value.subjectOneName}
                      </span>
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {value.subjectOneNumber}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {value.subjectTwoName}
                      </span>
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {value.subjectTwoNumber}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {value.subjectThreeName}
                      </span>
                      <span
                        style={{
                          color: "#565656",
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          fontWeight: 400,
                        }}
                      >
                        {value.subjectThreeNumber}
                      </span>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <hr className="mt-3" />
          

        </div>

          <div className="row align-items-center" style={{height:"5%"}} >
            <div className="col-6 p-0 h-100">
                <button type="button" className="w-100 py-2 h-100" style={{border:"0",background:"#EDF7FF",fontSize:"0.85rem",fontWeight:500,fontStyle:"normal",fontFamily:"Archivo",color:"#3774A5"}}
                onClick={fetchHtmlContent}
                ><BsFiletypePdf/> Download PDFs</button>
            </div>
            <div className="col-6 p-0 h-100" >
                <button  className="w-100 py-2 h-100" style={{border:"0",background:"#fff",boxShadow:"0px 1px 12px 0px rgba(0, 0, 0, 0.12)",fontSize:"0.85rem",fontWeight:500,fontStyle:"normal",fontFamily:"Archivo",color:"#000"}}
                onClick={()=>{deletePaper(id)}}
                > <MdOutlineModeEditOutline/> Delete Paper</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPaperDetails;