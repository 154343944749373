import React, { useEffect, useState } from 'react';
import sort from "../assets/sort.svg";
import plus from "../assets/plus.svg";
import CourseCard from './CourseCard';
import Offcanvas from "react-bootstrap/Offcanvas";
import { API_URL } from '../../env';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ImSearch } from 'react-icons/im'

const TopicsData = () => {

  const { id } = useParams();
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [topic_name, setTopics_name] = useState("");
  const [searchTerm, setSearchTerm] = useState("")
  const [isInputBoxVisible, setIsInputBoxVisible] = useState(false);
  const [filteredTopics, setFilteredTopics] = useState([]);

  const showTopic = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('question_bank_token')}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    setIsLoading(true);
    fetch(`${API_URL}/show/topic/byid/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.status === 1) {
          setTopic(result.topicDetails);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log('error', error));
  };

  const addTopic = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "topic_name": topic_name,
      "subject_id": id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/add/topic`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          handleClose();
          // toast.success(result.message);
          showTopic();
          setTopics_name("");
        } else if (result.status === "VAL_ERR") {
          toast.error(result.Backend_Error);
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    const filteredResults = value
      ? topic.filter((t) =>
        t.topic_name.toLowerCase().includes(value.toLowerCase())
      )
      : topic;
    setFilteredTopics(filteredResults);
  };

  const remove = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/remove/topic/by/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          toast.success(result.message);
          showTopic()
        }
      })
      .catch(error => console.log('error', error));
  }

  const toggleInputBox = () => {
    setIsInputBoxVisible(!isInputBoxVisible);
  };

  useEffect(() => {
    showTopic();
  }, []);

  return (
    <>
      <Toaster />
      <div className="container h-100">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="row align-items-center">
              <div className="col-6">
                <h1 style={{ fontFamily: "Archivo", color: "#000", fontWeight: 500, fontStyle: "normal", fontSize: "1.5rem" }} >Topics</h1>
              </div>
              <div className="col-6 text-end d-flex justify-content-end align-items-center gap-3" >
                <div className="p-1" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                  <ImSearch className='m-1' onClick={toggleInputBox} />
                </div>
                <div className="p-1" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                  <img src={sort} alt="sort" />
                </div>
                <div className="p-1 px-2" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                  <img src={plus} onClick={handleShow} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3 example" style={{ height: "80vh", overflowY: "auto" }} >
            {isInputBoxVisible && (
              <div className='mt-4'>
                <input
                  value={searchTerm}
                  onChange={handleChange}
                  type="text"
                  className="w-100 p-2"
                  placeholder="Topic name"
                  style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                />
              </div>
            )}
            <CourseCard
              topic={topic}
              filter={searchTerm ? filteredTopics : topic}
              isLoading={isLoading}
              remove={remove}
            />
          </div>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "30%" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            className="d-flex justify-content-center mx-auto"
            onClick={handleClose}
          >
            <div
              style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
            ></div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1
            className="text-center"
            style={{
              color: "#000",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Add Topic
          </h1>
          <div className="d-flex flex-column justify-content-between h-75" >
            <div className="d-flex flex-column gap-3">
              <input
                value={topic_name}
                onChange={(e) => setTopics_name(e.target.value)}
                type="text"
                className="w-100 p-2"
                placeholder="Topic name"
                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
              />
            </div>

            <div>
              <button
                onClick={() => addTopic()}
                className="w-100 py-2 border-0"
                style={{
                  background: "#EDF7FF",
                  borderRadius: "5px",
                  color: "#3774A5",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Add topic
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>


    </>
  )
}

export default TopicsData