import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import backButton from '../assets/backButton.svg';
import Offcanvas from "react-bootstrap/Offcanvas";
const Personalization = () => {
  const data = [
    {
      section: '33 days',
    }
  ]
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Navigate = useNavigate();
  const [def, setDef] = useState('https://placehold.co/150x150');
  // const [def, setDef] = useState("");
  const [name, setName] = useState("");
  const inputFileRef = useRef(null);

  const handleProfileChange = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const fileUrl = URL.createObjectURL(file);
      setDef(fileUrl);
    }
  };
  return (
    <div>
        <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "60%" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            className="d-flex justify-content-center mx-auto"
            onClick={handleClose}
          >
            <div
              style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
            ></div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1
            className="text-center"
            style={{
              color: "#000",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Edit Organisation Profile
          </h1>
          <div className="d-flex flex-column justify-content-between h-75 mt-3">
            <div className="d-flex flex-column gap-3">
              <input
                defaultValue={name}
                type="text"
                // onChange={(e) => setName(e.target.value)}
                className="w-100 p-2"
                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                // onClick={() => handleClose()}
                className="w-50 py-2 border-0"
                style={{
                  background: "#EDF7FF",
                  borderRadius: "5px",
                  color: "#3774A5",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex mt-2" style={{ justifyContent: 'space-between' }}>
              <img onClick={() => Navigate('/Home')} src={backButton} alt="back" />
              <h1 onClick={() => Navigate('/Home')} style={{ fontSize: '1.3rem', color: '# ' }}>
                Save
              </h1>
            </div>

            <div>
              <div
                style={{
                  height: '30vh',
                  border: 'red',
                  width: '100%',
                  display: 'flex',
                  flexDirection: "column",
                  justifyContent: 'center',
                  alignItems: 'center',
                }
                }
                className='mt-3'
              >
                <div style={{ width: '150px', height: '150px', overflow: 'hidden' }}>
                  <img
                    src={def}
                    alt="dsg"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                </div>

                <p onClick={handleProfileChange} style={{ color: "" }}>Change Logo</p>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={inputFileRef}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
            </div>

            <div className="d-flex" style={{ justifyContent: 'space-between', marginTop: '2rem' }}>
              <p> Company Name</p>
              <h1 >
                <MdEdit style={{ fontSize: "20px" }} onClick={handleShow}/>
              </h1>
            </div>
            <h1>We RNS IT solution</h1>

            {/* Save button */}
            {
              data.map((res, index) => {
                return (
                  <div className='card mt-3' >
                    <div className='card-body'>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>Plan Validity</h4>
                        <h6>{res.section}</h6>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personalization
