import React, { useEffect, useState } from 'react';
import "./CreateMain.css"
import BottomButton from './BottomButton';
import SelectBoard from './SelectBoard';
import { RxCross1 } from 'react-icons/rx';
import ProgressBar from "@ramonak/react-progress-bar";
import SelectCourse from './SelectCourse';
import SelectTopic from './SelectTopic';
import CreateMcq from './CreateMcq';
import CreateShortQues from './CreateShortQues';
import CreateLongQues from './CreateLongQues';
import CreateFillUps from './CreateFillUps';
import Modal from 'react-bootstrap/Modal';
import CreateSections from './CreateSections';
import SetAndExam from './SetAndExam';
import FinalSemExam from './FinalSemExam';
import backButton from "../assets/backButton.svg";
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../env';
import SelectSubject from './selectSubject';
import toast, { Toaster } from 'react-hot-toast';

const CreateMain = () => {

  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [progressState, setProgressState] = useState(1);
  const [showboard, setShowboard] = useState([]);
  const [boardId, setBoardID] = useState("");
  const [showcourses, setCourses] = useState([]);
  const [courseId, SetCourseId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [topics, setTopic] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [sectionID, setSectionID] = useState([]);
  const [short_question_marks, setShortMarks] = useState();
  const [long_question_marks, setlongMarks] = useState();
  const [fill_question_marks, setfillMarks] = useState();
  const [mcq_question_marks, setmcqMarks] = useState();
  const [instructions, setInstructions] = React.useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [sections, setSections] = useState([]);
  const [mcqCompulsory,setMcqCompulsory] = useState('');
  const [fillUpCompulsory,setFillUpCompulsory] = useState('');
  const [shortCompulsory,setShortCompulsory] = useState('');
  const [longCompulsory,setLongCompulsory] = useState('');
  const [add, setAdd] = useState({
    que_paper_name: "",
    duration: "",
    mcq_mpq: "",
    fillup_mpq: "",
    short_mpq: "",
    long_mpq: "",
    
  })

  const handleBoardSelect = (selectedBoardId) => {
    setBoardID(selectedBoardId);
  };

  const handleCourseSelect = (selectedCourseId) => {
    SetCourseId(selectedCourseId);
  };

  const handleSubjectSelect = (selectedSubjectId) => {
    setSubjectId(selectedSubjectId);
  };

  const handleTopicSelected = (selectedTopic) => {
    setSelectedTopics(selectedTopic)
  }

  const showSections = (section) => {
    setSections(section);
  }

  const handleRefresh = () => {
    setRefresh(true);
  }

  const handleSection = (selectSection) => {
    setSectionID(selectSection);
  };

  const handleShortQuestionMarks = (data) => {
    setShortMarks(data);
  };
  const handlelongQuestionMarks = (data) => {
    setlongMarks(data);
  };
  const handlefillQuestionMarks = (data) => {
    setfillMarks(data);
  };
  const handlemcqQuestionMarks = (data) => {
    setmcqMarks(data);
  };

  const handleMcqComp=(data)=>{
    setMcqCompulsory(data);
  }
  const handleFillUpComp=(data)=>{
    setFillUpCompulsory(data);
  }
  const handleShortComp=(data)=>{
    setShortCompulsory(data);
  }
  const handleLongComp=(data)=>{
    setLongCompulsory(data);
  }

  console.log(mcq_question_marks)
  const handleInstructionsChange = (newInstructions) => {
    setInstructions(newInstructions);
  };

  const options = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${API_URL}/show/all/board`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setShowboard(result.allBoards);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log("error", error));
  };

  const course = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${API_URL}/get/course/by/boardid/${boardId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setCourses(result.courseDetails);
        if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch(error => console.log('error', error));
  };

  const showSubjects = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${API_URL}/show/subject/by/courseid/${courseId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setSubjects(result.subjectDetails);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log("error", error));
  };

  const showTopic = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('question_bank_token')}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    setIsloading(true)
    fetch(`${API_URL}/show/topic/byid/${subjectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsloading(false)
        if (result.status === 1) {
          setTopic(result.topicDetails);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log('error', error));
  };

  const addQuestion = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "board_id": boardId,
      "course_id": courseId,
      "subject_id": subjectId,
      "section_ids": sectionID,
      "instructions": instructions,
      "mcq_no_of_Question": mcq_question_marks,
      "fillup_no_of_Question": fill_question_marks,
      "short_no_of_Question": short_question_marks,
      "long_no_of_Question": long_question_marks,
      "mcq_compulsory": mcqCompulsory,
    "fillup_compulsory": fillUpCompulsory,
    "short_compulsory": shortCompulsory,
    "long_compulsory": longCompulsory,
      ...add
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/add/question/paper`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          Navigate('/CreatedSuccessfully')
        } else if (result.status === "VAL_ERR") {
          toast.error(result.Backend_Error);
        }
      })
      .catch(error => console.log('error', error));
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdd({
      ...add,
      [name]: value
    })
  }

  console.log(add.mcq_compulsory);

  const updateProgressState = () => {
    if (progressState < 10) {
      setProgressState((prevProgressState) => prevProgressState + 1)
    }
  };

  useEffect(() => {
    options();
    if (boardId) {
      course();
    }
    if (courseId) {
      showSubjects();
    }
    if (subjectId) {
      showTopic();
    }
    if (refresh) {
      setRefresh(false);
    }
  }, [boardId, courseId, subjectId, refresh]);

  const validateAndProceed = () => {
    if (progressState === 1 && !boardId) {
      toast.error('Please select a board.');
      return false;
    }

    if (progressState === 2 && !courseId) {
      toast.error('Please select a course.');
      return false;
    }

    if (progressState === 2 && !subjectId) {
      toast.error('Please select a subject.');
      return false;
    }
    if (progressState === 3 && !selectedTopics.length) {
      toast.error('Please select at least one topic for MCQ.');
      return false;
    }
    if (progressState === 4 && !add.mcq_mpq && !add.mcq_compulsory) {
      toast.error('Please enter marks per question');
      return false;
    }
    if (progressState === 5 && !add.short_mpq && !add.short_compulsory) {
      toast.error('Please enter marks per question ');
      return false;
    }
    if (progressState === 6 && !add.long_mpq && !add.long_compulsory) {
      toast.error('Please enter marks per question ');
      return false;
    }
    if (progressState === 7 && !add.fillup_mpq && !add.fillup_compulsory) {
      toast.error('Please enter marks per question ');
      return false;
    }
    if (progressState === 8 && !sectionID.length) {
      toast.error('Please add sectiions');
      return false;
    }
    // if (progressState === 9 && !add.no_of_sets) {
    //   toast.error('Please enter number of sets required ');
    //   return false;
    // }
    if (progressState === 9 && !add.que_paper_name) {
      toast.error('Please enter Question paper name');
      return false;
    }
    if (progressState === 9 && !add.duration) {
      toast.error('Please paper duration');
      return false;
    }
    if (progressState === 9 && !instructions.length) {
      toast.error('Please enter instructions for this paper ');
      return false;
    }
    return true;
  };

  return (
    <>
      <div className='main' >
        <Toaster />
        <div style={{ height: "20%" }} >
          <div className='container h-100'>
            <div className='row h-100 align-items-center' >
              <div className='col-12' >
                <div className='d-flex justify-content-between align-items-center' >
                  {
                    progressState == 1 ?
                      <> </> :
                      <>
                        <img
                          onClick={() => setProgressState(progressState - 1)}
                          src={backButton}
                          alt="back"
                        />
                      </>
                  }
                  <h1 style={{ textAlign: progressState == 1 ? "start" : "center", fontSize: "2rem" }}>Create New <br></br>Question Paper</h1>
                  <h1 onClick={handleShow}><RxCross1 /></h1>
                </div>
              </div>

              <div className='col-12'>
                <ProgressBar
                  completed={progressState}
                  bgColor=" linear-gradient(89deg, #3774A5 0%, #2BA1FF 93.2%)"
                  height="10px"
                  isLabelVisible={false}
                  baseBgColor="#E4E9EC"
                  labelColor="#e80909"
                  animateOnRender
                  maxCompleted={10}
                />
                <p className='inc'>{progressState}/10</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "60%", overflowY: "auto", }} >
          {progressState === 1 &&
            <SelectBoard
              showboard={showboard}
              onBoardSelect={handleBoardSelect} />}
          {progressState === 2 &&
            <SelectCourse
              showcourse={showcourses}
              onCourse={handleCourseSelect} />}
          {progressState === 2 &&
            <SelectSubject
              showSubject={subjects}
              onSubject={handleSubjectSelect} />}
          {progressState === 3 &&
            <SelectTopic
              showTopic={topics}
              onTopicAdded={handleRefresh}
              subjectId={subjectId}
              onTopics={handleTopicSelected}
              loading={isLoading} />}
           {progressState === 4 &&
            <CreateMcq
          topics={selectedTopics}
          handleChange={handleChange}
          onmcq={handlemcqQuestionMarks}
          handleMcqComp={handleMcqComp}
        />}
          {progressState === 5 &&
            <CreateShortQues
              topics={selectedTopics}
              handleChange={handleChange}
              onShort={handleShortQuestionMarks}
              handleShortComp={handleShortComp}
               />}
          {progressState === 6 &&
            <CreateLongQues
              topics={selectedTopics}
              handleChange={handleChange}
              onlong={handlelongQuestionMarks}
              handleLongComp={handleLongComp}
               />}
          {progressState === 7 &&
            <CreateFillUps
              topics={selectedTopics}
              handleChange={handleChange}
              onfill={handlefillQuestionMarks}
              handleFillUpComp={handleFillUpComp}
               />}
          {progressState === 8 &&
            <CreateSections
              handleChange={handleChange}
              onSection={handleSection}
              showSection={showSections} />}
          {progressState === 9 &&
            <SetAndExam
              handleChange={handleChange}
              onInstructionsChange={handleInstructionsChange} />}
          {progressState === 10 &&
            <FinalSemExam
              add={add}
              selectedTopics={selectedTopics}
              sections={sections}
              mcqCompulsory={mcqCompulsory}
              shortCompulsory={shortCompulsory}
              longCompulsory={longCompulsory}
              fillUpCompulsory={fillUpCompulsory}
               />}
        </div>
        <div style={{ height: "20%", }} >
          {
            progressState !== 10 ?
              <>
                <BottomButton updateProgressState={updateProgressState} validateAndProceed={validateAndProceed} />
              </> :
              <>
                <div className='container h-100' >
                  <div className='row align-items-center justify-content-center  h-100' >
                    <div className='col-12'>
                      <button onClick={() => { addQuestion() }} className='nextButtonClass w-100 p-3 mx-auto text-center' >Create Paper</button>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className='outline' style={{ display: "flex", height: "30vh", flexDirection: "column", justifyContent: "center" }}>
          <div >
            <div className=''>
              <h1 className='sets' style={{ border: "0" }}>Stop Creating Paper</h1>
              <h1 className='action'>The action cannot be reversed</h1>
            </div>
            <div className='d-flex justify-content-center'>
              <button className='cancel ' onClick={handleClose}>Cancel</button>
            </div>
            <div className='d-flex justify-content-center mt-2'>
              <button className='ahead' onClick={() => { Navigate(-1) }}>Process Ahead</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreateMain