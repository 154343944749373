import React, { useState } from 'react'
import { useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Select from 'react-select';
import { API_URL } from '../../env';
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../common/Loader';
import { MdDelete } from "react-icons/md";


const optionsOBJ = [
  { value: 'fillup', label: 'Fill in the Blanks' },
  { value: 'long', label: 'Long Questions' },
  { value: 'short', label: 'Short Questions' },
  { value: 'mcq', label: 'Multiple Choice Questions' },
];

const CreateSections = ({ onSection, showSection }) => {



  const [selectedOption, setSelectedOption] = useState(null);
  const [sectionName, setSectionName] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ID, setID] = useState('');
  const [sectionIds, setSectionIds] = useState([]);
  const [section, setSection] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  
  const [options, setOptions] = useState(optionsOBJ);

  
  const deleteSection = async(id , value)=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("question_bank_token")}`
      );
      myHeaders.append(
        "Contet-Type","application/json"
      );

var requestOptions = {
  method: 'DELETE',
  headers: myHeaders,
  redirect: 'follow'

};

fetch(`${API_URL}/delete/section/byid/${id}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    
    
    if (result.status == 1) {
      toast.success("section deleted Successfully")
      console.log(result.message)
      setSectionIds(sectionIds.filter((ids)=>ids !== id));

      let deletedOption = optionsOBJ.find((opt)=>opt.value == value)
      setOptions([...options,deletedOption])

    }
    else{


      
      toast.error(result.message)
    }
  
  
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error)
    }
  }






  const data = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "section_ids": sectionIds
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsloading(true);
    fetch(`${API_URL}/show/section/byid`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsloading(false)
        if (result.status == 1) {
          setSection(result.section_details);
          console.log("section : ", result.section_details )
          // showSection(section);
        }
      })
      .catch(error => console.log('error', error));
  }

  localStorage.setItem("sections",sectionIds)
  const addSection = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "section_name": sectionName,
      "questions_type": selectedOption?.value
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsloading(true)
    fetch(`${API_URL}/add/section`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsloading(false)
        if (result.status == 1) {
          // toast.success(result.message);
          handleClose();
          setID(result.section_id)
          setSectionName("")
          setSelectedOption(null);
          data();
          setSectionIds(prevIds => [...prevIds, result.section_id]);


          setOptions(options.filter((opt)=>opt.value !== selectedOption.value))

        } else if (result.status === "VAL_ERR") {
          toast.error(result.Backend_Error);
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    onSection(sectionIds);
    data();
  }, [sectionIds, onSection ]);


  return (
    <>
      <div className='container'>
        <Toaster />
        <div className='row'>
          <div className='col-12'>
            <div className='row align-items-center'>
              <div className='col-8'>
                <div style={{ lineHeight: "0px" }}>
                  <h1 className='createPaperHeading'>Create Sections</h1>
                </div>
              </div>
              <div className='col-4'>
                <button onClick={handleShow} className='addbtn'>+Add</button>
              </div>
            </div>
            {
              isLoading ? <Loader show={isLoading} /> :
                <div className='row' style={{ height: "58vh", overflowY: "auto" }}>
                  <div className='col-12 pt-4' >
                    {
                      section.length > 0 ?
                        section.map((res, index) => {
                          return (
                            <div className='card mt-3' >
                              <div className='card-body'>

                                <div style={{display:"flex" , justifyContent:"space-between"}}>

                                  <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <h4>{res.section_name}</h4>
                                  {/* <h6>{res.marks}</h6> */}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  {
                                    res.questions_type == "fillup" ?
                                      <h6>Fill in the Blanks</h6> :
                                      res.questions_type == "long" ?
                                        <h6 >Long Questions</h6> :
                                        res.questions_type == "short" ?
                                          <h6 >Short Questions</h6> :
                                          <h6 >Multiple Choice Questions</h6>
                                  }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  {/* <h6 style={{ color: '#565656' }}>{"hello"}</h6> */}
                                  {/* <h6 style={{ color: '#565656' }}>{res.a12}</h6> */}
                                </div>
                                </div>

                                  <div >
                                    <MdDelete onClick={()=>{deleteSection(res.section_id , res.questions_type)}} className='mt-3' style={{fontSize:30 , cursor:"pointer"}}/>
                                  </div>

                                </div>


                              </div>
                            </div>
                          )
                        }) : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>Add Section </h4></>
                    }
                  </div>
                </div>
            }

          </div>
        </div>

      </div>
      <Offcanvas className='border' show={show} onHide={handleClose} placement='bottom' style={{ height: "50%", }} backdrop="static">
        <Offcanvas.Header className='w-100'>
          <div onClick={handleClose} style={{fontSize:"25px",cursor:"pointer" , width: "50px" }}>X</div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <h4 className='sectionadd' style={{ textAlign: "center" }}>Add Section</h4>
          </div>
          <div className='section-name '>
            <div className='pt-3'>
              <input value={sectionName} onChange={(e) => setSectionName(e.target.value)} placeholder='Section Name' type='text' className='w-100 p-3 ' style={{ border: '1px solid #E5E5E5' }} ></input>
            </div>
            <div className='pt-5 pb-2'>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
                styles={{ overflowY: 'scroll' }}
                placeholder='Section Type'
              />
            </div>
          </div>
          <div className='mt-5 lastbtnoutline'>
            <button type='submit' onClick={() => addSection()} className='addsecbtn p-3' style={{ width: "100%" }}>Add Section</button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default CreateSections
