// import logo from './logo.svg';
import "./App.css";
import CreateMain from "./componenets/createpaper/CreateMain";
import { Routes, Route } from "react-router-dom";
import Home from "./componenets/home/Home";
import History from "./componenets/history/History";
import Topics from "./componenets/topics/Topics";
import ViewPaperDetails from "./componenets/history/ViewPaperDetails";
import EditPaperDetails from "./componenets/history/EditPaperDetails";
import QuestionComposition from "./componenets/history/QuestionComposition";
import SectionComposition from "./componenets/history/SectionComposition";
import CreatedSuccessfully from "./componenets/createpaper/CreatedSuccessfully";
import Course from "./componenets/topics/Course";
import SubCard from "./componenets/topics/SubCard";
import Subjects from "./componenets/topics/Subjects";
import Login from "./componenets/createpaper/Login";
import CouTopic from "./componenets/topics/CouTopic";
import CourseCard from "./componenets/topics/CourseCard";
import Questions from "./componenets/topics/Questions";
import Profile from "./componenets/home/Profile";
import Navbar from "./componenets/home/Navbar";
import Personalization from "./componenets/home/Personalization";
import ProtectedRoute from "./ProtectedRoute";
import Board from "./componenets/topics/board";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/Home" element={<Home />} />
        <Route path="/CreatePaper" element={<CreateMain />} />
        <Route path="/History/:status" element={<History />} />
        <Route path="/Topics/:id" element={<Topics />} />
        <Route path="/ViewPaperDetails/:id" element={<ViewPaperDetails />} />
        <Route path="/EditPaperDetails/:id/:paperName" element={<EditPaperDetails />} />
        <Route path="/QuestionComposition" element={<QuestionComposition />} />
        <Route path="/SectionComposition" element={<SectionComposition />} />
        <Route path="/CreatedSuccessfully" element={<CreatedSuccessfully />} />
        <Route path="/Course" element={<Course />} />
        <Route path="/Subcard/:select/:boardId" element={<SubCard />} />
        <Route path="/Subjects/:id" element={<Subjects />} />
        <Route path="/AllTopics" element={<CouTopic />} />
        <Route path="/card" element={<CourseCard />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Navbar" element={<Navbar />} />
        <Route path="/Subjects/:course_name/:id" element={<Subjects />} />
        <Route path="/Quest/:id/:status" element={<Questions />} />
        <Route path="/Quest" element={<Questions />} />
        <Route path="/Personalization" element={<Personalization />} />
        <Route path="/Board" element={<Board />} />
      </Route>
    </Routes>
  );
}

export default App;
