import React, { useEffect, useState } from "react";
import Course from "./Course";
import book from "../assets/book.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../common/Loader";
import { API_URL } from "../../env";

const SubCard = () => {

  const Navigate = useNavigate();
  const { select, boardId } = useParams();
  const [courses, setCourses] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCourse = () => {
    setRefresh(true);
  }

  const subData = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsLoading(true)
    fetch(`${API_URL}/get/course`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result.status == 1) {
          setCourses(result.courseDetails)
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log("error", error));
  };

  const course_by_id = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`${API_URL}/get/course/by/boardid/${boardId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setCourses(result.courseDetails);
        setIsLoading(false)
        if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch(error => console.log('error', error));
  };

  const course_sort_by_A_Z = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/get/course/by/alphabets`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          setCourses(result.courseDetails)
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (select == 1) {
      subData();
    } else {
      course_by_id();
    }
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh])

  return (
    <div>
      <Course onCourseAdded={handleCourse} course_sort_by_A_Z={course_sort_by_A_Z} select={select}/>
      <div className="container">
        <div className="row">
            {
              isLoading ? <Loader show={isLoading} /> :
                <div
                  className="example"
                  style={{ height: "83vh", overflowY: "scroll" }}
                >
                  {courses.length > 0 ?
                    courses.map((res, index) => {
                      return (
          <div className="col-lg-6 col-md-6 col-sm-12">
                        <div
                          className="card mt-3 p-0  "
                          style={{ border: "1px solid black" }}
                        >
                          <div className="card-body p-0  p-2">
                            <h1
                              style={{
                                color: "#000",
                                fontFamily: "Archivo",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontSize: "20px",
                              }}
                            >
                              {" "}
                              <img src={book} />
                              {res.course_name}
                            </h1>

                            <div style={{ lineHeight: "" }}>
                              <p
                                style={{
                                  margin: "0%",
                                  color: "#3774A5",
                                  fontFamily: "Archivo",
                                  fontWeight: 400,
                                  fontStyle: "normal",
                                  fontSize: "0.85rem",
                                }}
                              >
                                Number of Subject : {res.no_of_subjects}
                              </p>
                              <p
                                style={{
                                  margin: "0%",
                                  color: "#4EC53B",
                                  fontFamily: "Archivo",
                                  fontSize: "0.85rem",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  background: "#ECFFE9",
                                  borderRadius: "10px",
                                  width: "max-content",
                                }}
                                className="my-2 mb-2 p-1 px-2"
                              >
                                Topics : {res.no_of_topics}
                              </p>
                            </div>
                            <div style={{
                              display: "flex",
                              justifyContent: "end"
                            }}>
                              <button
                                className="py-2 mb-2 me-2 p-4"
                                onClick={() => Navigate(`/Subjects/${res.course_name}/${res.course_id}`)}
                                style={{
                                  border: "1px solid #fff",
                                  borderRadius: "5px",
                                  color: "#fff",
                                  fontSize: "0.85rem",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  backgroundColor: "rgba(85,101,206,255)"
                                }}
                              >
                                View Subjects
                              </button>
                            </div>
                          </div>
                        </div>
          </div>
                      );
                    }) : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>No Courses added</h4></>
                  }
                </div>
            }
        </div>
      </div>
    </div>
  );
};

export default SubCard;
