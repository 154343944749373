import React from 'react'
import TopicsData from './TopicsData'
import backButton from "../assets/backButton.svg"
import { useNavigate } from "react-router-dom";

const Topics = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-sm-12 mt-2'>
            <div style={{}} >
              <div className='d-flex' style={{}}>
                <img
                  onClick={() => Navigate(-1)}
                  src={backButton}
                  alt="back"
                  style={{}}
                />
                <div style={{ marginLeft: "6rem", display: "flex", alignItems: "center" }}>
                  <h2 >{localStorage.getItem("subject_name")}</h2>
                </div>
              </div>
              <div style={{ height: "86%", overflowY: "auto" }} >
                <TopicsData />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Topics
