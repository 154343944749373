import React, { useState } from 'react'
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const CreateLongQues = ({ handleChange, topics ,onlong,handleLongComp}) => {

  const [subjectData, setSubjectData] = useState({});
  const [compulsoryValue, setCompulsoryValue] = useState('');
  const handleInputChange = (e,maxQuestions) => {
    const { id, value } = e.target;
    const numericValue = value === '' ? 0 : parseInt(value, 10);
    if (numericValue > maxQuestions) {
      toast.error(`max ${maxQuestions} allowed`);
      return;
    }
    setSubjectData({ ...subjectData, [id]: numericValue });
  };

  const getTotalQuestions = () => {
    return Object.values(subjectData).reduce((total, value) => total + (isNaN(value) ? 0 : value), 0);
  };

  const handleCompulsoryChange = (e) => {
    const {value } = e.target;
    const numericValue = value === '' ? 0 : parseInt(value, 10);
    const totalQuestions = getTotalQuestions();
    if (numericValue > totalQuestions) {
      toast.error(`max ${totalQuestions} allowed`);
      return ;
    }
      setCompulsoryValue(numericValue);
  };

  useEffect(() => {
    onlong(subjectData);
    handleLongComp(compulsoryValue)
  }, [subjectData, onlong,compulsoryValue,handleLongComp]);

  return (
    <>
      <div className='container h-100'>
      <Toaster/>
        <div className='row h-100'>
          <div className='col-12'>
            <div>
              <h1 className='createPaperHeading'>Create Long Ques Composition</h1>
            </div>
            <div style={{ overflowY: "scroll", height: "45vh" }}>
              {topics.map((subject) => (
                <div key={subject.topic_id} style={{ display: "flex", justifyContent: "space-between", padding: "20px", textAlign: "center", borderBottom: "1px solid #DCDCDC" , textTransform:"capitalize"}}>
                  <span className='input'>{subject.topic_name}</span>
                  <input
  className='inputbutton'
  type='number'
  id={subject.topic_id}
  value={subjectData[subject.topic_id] != null ? String(subjectData[subject.topic_id]) : '0'}

  onChange={(e) => handleInputChange(e, subject.long_no_of_questions)}
/>

                </div>
              ))}
            </div>

          </div>
          <div className='col-12' style={{ display: "flex", gap: "1rem" }}>
            <div>
              <h1 className='createPaperHeading'>MPQ</h1>
              <input
                name='long_mpq'
                onChange={handleChange}
                className='inputbutton'
                type='number'
              />
            </div>
            <div>
              <h1 className='createPaperHeading'>Compulsory</h1>
              <input
                name='long_compulsory'
                value={compulsoryValue}
                onChange={handleCompulsoryChange}
                className='inputbtn'
                type='number'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateLongQues