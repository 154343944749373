import React from 'react'
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import { API_URL } from '../../env';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { Loader } from '../common/Loader'
import toast, { Toaster } from 'react-hot-toast';
import {useNavigate } from 'react-router-dom';

const TabsMcq = ({ status }) => {

    const [selectedMcq, setSelectedMcq] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const { id } = useParams();
    const [mcq, setMCQ] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const Navigate = useNavigate();

    const [add, setAdd] = useState({
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: ""
    })

    const [defaultEditValues, setDefaultEditValues] = useState({
        question_id: selectedMcq?.question_id,
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: ""
    });

    const uploadExcel = async () => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);
    
        let formData = new FormData();
        formData.append("topic_id", String(id));
        formData.append("excel", selectedFile);
    
        await fetch(`${API_URL}/add/mcq/bulk/question`, {
          method: "POST",
          headers: myHeaders,
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status == 1) {
              toast.success(result.message)
              handleClose1()

            }
            else if(result.status=="FILE_ERR"){
                toast.error(result.Backend_Error)
            }
            else if(result.status==111){
            localStorage.removeItem("question_bank_token");
            Navigate('/')
             }
            else{
              toast.error(result.Backend_Error)
            }
          })
    
          .catch((error) => {
            toast.error("Invalid Format")
            console.log("error", error)});
      
      };



    const getExcel = async () => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);
    

    
        await fetch(`${API_URL}/download/mcq/excel/format`, {
          method: "GET",
          headers: myHeaders,
        })
          .then((response) => response.blob())
          .then((result) => {
              console.log("excel" , result)
              const url = URL.createObjectURL(result);
              setDownloadUrl(url);
          })
    
          .catch((error) => console.log("error", error));

      };
    

    const mcqData = async() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        setIsLoading(true)
        fetch(`${API_URL}/show/mcq/questions/byid/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setIsLoading(false)
                if (result.status == 1) {
                    setMCQ(result.mcq_question_details);
                }else if(result.status==111){
                    localStorage.removeItem("question_bank_token");
                    Navigate('/')
                }
            })
            .catch(error => console.log('error', error));
    }

    const question = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        setIsLoading(true);
        fetch(`${API_URL}/show/mcq/questions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                        setIsLoading(false)
                if (result.status == 1) {
                    setMCQ(result.mcq_question_details)
                }else if(result.status==111){
                    localStorage.removeItem("question_bank_token");
                    Navigate('/')
                }
            })
            .catch(error => console.log('error', error));
    }

    const addMCQ = async() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "topic_id": id,
            ...add
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/add/mcq/question`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    handleClose();
                    // toast.success(result.message);
                    mcqData();
                }
                else if (result.status == "VAL_ERR") {
                    toast.error(result.Backend_Error);
                }
            })
            .catch(error => console.log('error', error));
    }

    const editMCQ = async() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            ...defaultEditValues
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/edit/mcq/questions/byid`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    handleClose2();
                    // toast.success(result.message);
                    mcqData();
                }
                else if (result.status == "VAL_ERR") {
                    toast.error(result.Backend_Error);
                }
            })
            .catch(error => console.log('error', error));
    }

    const Delete = async(id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "question_id": id,
            "type": "mcq"
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/delete/questions/byid`, requestOptions)
            .then(response => response.json())
            .then(result => {
                          if (result.status == 1) {
                    // toast.success(result.message)
                    mcqData();
                } else if (result.status == "VAL_ERR") {
                    toast.error(result.Backend_Error);
                } else {
                    console.log(result.error)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleShow2 = (index) => {
        setSelectedMcq(mcq[index]);
        setShow2(true);
    };

    const handleCange = (e) => {
        const { name, value } = e.target;
        setAdd({
            ...add,
            [name]: value
        })
    }

    const handleEdit = (e) => {
        const { name, value } = e.target;
        setDefaultEditValues((prevEdit) => ({
            ...prevEdit,
            [name]: value
        }));
    };

    useEffect(() => {
        if (selectedMcq) {
            setDefaultEditValues({
                question_id: selectedMcq.question_id || "",
                question: selectedMcq.question || "",
                option1: selectedMcq.option1 || "",
                option2: selectedMcq.option2 || "",
                option3: selectedMcq.option3 || "",
                option4: selectedMcq.option4 || ""
            });
        }
    }, [selectedMcq]);

    useEffect(() => {
        getExcel()
        if (status == "Question_Bank") {
            question();
        } else {
            mcqData();
        }
    }, [])

    return (
        <>
            <Toaster />
            <div className=" ms-auto" style={{ display: "flex", justifyContent: "end" }}>
                {
                    status == "Question_Bank" ? "" :
                    <>
                    <div className='d-flex gap-2'>

                    <button className="btn px-3 ms-auto" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff",      backgroundColor: "rgba(85,101,206,255)", }} onClick={handleShow}>
                            Add MCQ
                        </button>
                        <button className="btn px-3 ms-auto" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff",      backgroundColor: "rgba(85,101,206,255)", }} onClick={handleShow1}>
                            Add in Bulk
                        </button>

                        </div>
                        </>
                }
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="bottom"
                    style={{ height: "80%" }}
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title
                            className="d-flex justify-content-center mx-auto"
                            onClick={handleClose}
                        >
                            <div
                                style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                            ></div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h1
                            className="text-center"
                            style={{
                                color: "#000",
                                fontSize: "1.25rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                            }}
                        >
                            Add MCQ
                        </h1>
                        <div className="d-flex flex-column justify-content-between mt-3">
                            <div className="d-flex flex-column gap-3">
                                <label style={{ fontWeight: "600" }}>Question</label>
                                <input
                                    name='question'
                                    type="text"
                                    className="w-100 p-2"
                                    // placeholder="Question"
                                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                    onChange={handleCange}
                                /><label>Option 1</label>
                                <input
                                    name='option1'
                                    type="text"
                                    className="w-100 p-2"
                                    // placeholder="Option"
                                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                    onChange={handleCange}
                                /><label>Option 2</label>
                                <input
                                    name='option2'
                                    type="text"
                                    className="w-100 p-2"
                                    // placeholder="Option"
                                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                    onChange={handleCange}
                                /><label>Option 3</label>
                                <input
                                    name='option3'
                                    type="text"
                                    className="w-100 p-2"
                                    // placeholder="Option"
                                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                    onChange={handleCange}
                                /><label>Option 4</label>
                                <input
                                    name='option4'
                                    type="text"
                                    className="w-100 p-2"
                                    // placeholder="Option"
                                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                    onChange={handleCange}
                                />
                            </div>

                            <div className='mt-3' >
                                <button
                                    onClick={() => addMCQ()}
                                    className="w-100 py-2 border-0 my-3"
                                    style={{
                                        background: "#EDF7FF",
                                        borderRadius: "5px",
                                        color: "#3774A5",
                                        fontSize: "0.875rem",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            {
                isLoading ? <Loader show={isLoading} /> : <>
                    {
                        mcq.length > 0 ?
                            mcq.map((res, index) => {
                                return (
                                    <>
                                        <div className='card p-2 border-0 mt-3' >
                                            <p > Q{index + 1}. {res.question}</p>
                                            <div>
                                                <ul>
                                                    <li>{res.option1}</li>
                                                    <li>{res.option2}</li>
                                                    <li>{res.option3}</li>
                                                    <li>{res.option4}</li>
                                                </ul>
                                            </div>
                                            {
                                            status == "Question_Bank" ?
                                                <>
                                                    <p
                                                        style={{
                                                            margin: "0%",
                                                            color: "#4EC53B",
                                                            fontFamily: "Archivo",
                                                            fontSize: "0.95rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            background: "#ECFFE9",
                                                            borderRadius: "10px",
                                                            width: "max-content",
                                                            textTransform: "capitalize"
                                                        }}
                                                        className="my-2 mb-2 p-1 px-2"
                                                    >
                                                        Subject : {res.subject_name}
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: "0%",
                                                            color: "#4EC53B",
                                                            fontFamily: "Archivo",
                                                            fontSize: "0.95rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            background: "#ECFFE9",
                                                            borderRadius: "10px",
                                                            width: "max-content",
                                                        }}
                                                        className="my-2 mb-2 p-1 px-2"
                                                    >
                                                        Topic : {res.topic_name}
                                                    </p>
                                                </> : ""
                                        }
                                            {
                                                status == "Question_Bank" ? "" :
                                                    <div className='row pb-2 ms-auto'>
                                                        <div className='col-6' >
                                                            <button className='w-100 py-2 btn' style={{ backgroundColor: "black", color: "white" }} onClick={() => handleShow2(index)}><FaEdit /></button>
                                                        </div>
                                                        <div className='col-6' >
                                                            <button className='w-100 py-2 btn' style={{ backgroundColor: "#AD1111", color: "white" }} onClick={() => Delete(res.question_id)} ><MdDelete /></button>
                                                        </div>
                                                    </div>
                                            }
                                            <hr />
                                            <Offcanvas
                                                show={show2}
                                                onHide={handleClose2}
                                                placement="bottom"
                                                style={{ height: "60%" }}
                                            >
                                                <Offcanvas.Header>
                                                    <Offcanvas.Title
                                                        className="d-flex justify-content-center mx-auto"
                                                        onClick={handleClose2}
                                                    >
                                                        <div
                                                            style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                                                        ></div>
                                                    </Offcanvas.Title>
                                                </Offcanvas.Header>
                                                <Offcanvas.Body>
                                                    <h1
                                                        className="text-center"
                                                        style={{
                                                            color: "#000",
                                                            fontSize: "1.25rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Edit MCQ
                                                    </h1>
                                                    <div className="d-flex flex-column justify-content-between h-75 mt-3">
                                                        <div className="d-flex flex-column gap-3">
                                                            <label style={{ fontWeight: "600" }}>Question</label>
                                                            <input
                                                                name='question'
                                                                type="text"
                                                                defaultValue={selectedMcq?.question || ""}
                                                                className="w-100 p-2"
                                                                placeholder="Question"
                                                                onChange={handleEdit}
                                                                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                                            /><label>Option 1</label>
                                                            <input
                                                                name='option1'
                                                                type="text"
                                                                defaultValue={selectedMcq?.option1 || ""}
                                                                className="w-100 p-2"
                                                                placeholder="Option"
                                                                onChange={handleEdit}
                                                                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                                            /><label>Option 2</label>
                                                            <input
                                                                name='option2'
                                                                type="text"
                                                                defaultValue={selectedMcq?.option2 || ""}
                                                                className="w-100 p-2"
                                                                placeholder="Option"
                                                                onChange={handleEdit}
                                                                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                                            /><label>Option 3</label>
                                                            <input
                                                                name='option3'
                                                                type="text"
                                                                defaultValue={selectedMcq?.option3 || ""}
                                                                className="w-100 p-2"
                                                                placeholder="Option"
                                                                onChange={handleEdit}
                                                                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                                            /><label>Option 4</label>
                                                            <input
                                                                name='option4'
                                                                type="text"
                                                                defaultValue={selectedMcq?.option4 || ""}
                                                                className="w-100 p-2"
                                                                placeholder="Option"
                                                                onChange={handleEdit}
                                                                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                                            />
                                                        </div>

                                                        <div>
                                                            <button
                                                                onClick={() => editMCQ()}
                                                                className="w-100 py-2 border-0 my-3"
                                                                style={{
                                                                    background: "#EDF7FF",
                                                                    borderRadius: "5px",
                                                                    color: "#3774A5",
                                                                    fontSize: "0.875rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Body>
                                            </Offcanvas>
                                        </div>
                                    </>
                                )
                            }) : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>No MCQ</h4></>
                    }
                </>
            }



{/* for adding in bulk */}
<Offcanvas
                    show={show1}
                    onHide={handleClose1}
                    placement="bottom"
                    style={{ height: "25%" }}
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title
                            className="d-flex justify-content-center mx-auto"
                            onClick={handleClose1}
                        >
                            <div
                                style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                            ></div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>

                    <div>
                        <div className='d-flex justify-content-between'>
      <div><h2>Upload Excel File</h2></div>


<div>
<a
    href={downloadUrl}
    download="MCQ_Que_Format.xlsx"
    className="w-100 p-2 border-0 "
    style={{
        textDecoration:"none",
        background: "#EDF7FF",
        borderRadius: "5px",
        color: "#3774A5",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 500,
    }}
>
    Download Format
</a>
</div>
      

      </div>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={(event)=>{    
            setSelectedFile(event.target.files[0]);}}
      />

                                                            <button
                                                                onClick={uploadExcel}
                                                                className="w-100 py-2 border-0 my-3"

                                                                style={{
                                                                    background: "#EDF7FF",
                                                                    borderRadius: "5px",
                                                                    color: "#3774A5",
                                                                    fontSize: "0.875rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Upload
                                                            </button>
                                                        </div>

                       
                    </Offcanvas.Body>
                </Offcanvas>
        </>
    )
}

export default TabsMcq;
