import React from 'react'

const SetAndExam = ({ handleChange, onInstructionsChange }) => {
    const [instructions, setInstructions] = React.useState([]);

    const handleInstructionsChange = (event) => {
        const newInstructions = event.target.value.split('\n');
        setInstructions(newInstructions);
        onInstructionsChange(newInstructions);
    };

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='p-3'>
                            {/* <h1 className='head'>Numbers of sets required</h1>
                            <div className='pt-3'>
                                <input name='no_of_sets' onChange={handleChange} placeholder='Number of Sets' type='text' className='w-100 p-3 ' style={{ border: '1px solid #E5E5E5' }} ></input>
                            </div> */}
                            <div className='pt-3'>
                                <h1 className='head'>Name of Exam</h1>
                                <div className='pt-3'>
                                    <input name='que_paper_name' onChange={handleChange} placeholder='Name of Exam' type='text' className='w-100 p-3 ' style={{ border: '1px solid #E5E5E5' }} ></input>
                                </div>
                            </div>
                            <div className='pt-5'>
                                <h1 className='head'>Duration of Exam</h1>
                                <div className='pt-3'>
                                    <input name='duration' onChange={handleChange} placeholder='Duration of Exam' type='text' className='w-100 p-3 ' style={{ border: '1px solid #E5E5E5' }} ></input>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                        <div className='pt-5'>
                            <h1 className='head'>Enter Instructions</h1>
                            <div class="form-floating">
                                <textarea
                                    value={instructions.join('\n')}
                                    onChange={handleInstructionsChange}
                                    class="w-100 p-2"
                                    rows={10}
                                    placeholder="Instructions for Exam"
                                    id="floatingTextarea"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetAndExam
