import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineCheck } from 'react-icons/ai';
import { FiFilter } from 'react-icons/fi';
import plus from "../assets/plus.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import toast, { Toaster } from 'react-hot-toast';
import { API_URL } from '../../env';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Loader } from '../common/Loader';

const SelectTopic = ({ showTopic, subjectId, onTopicAdded, onTopics, loading }) => {

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [topic_name, setTopic_name] = useState("");
  const Navigate = useNavigate();

  const handleSubjectClick = (topic_id, topic_name,mcq_no_of_questions,fillup_no_of_questions,short_no_of_questions,long_no_of_questions) => {
    setSelectedSubjects((prevTopics) => {
      const isAlreadySelected = prevTopics.some((topic) => topic.topic_id === topic_id);

      if (isAlreadySelected) {
        return prevTopics.filter((topic) => topic.topic_id !== topic_id);
      } else {
        return [
          ...prevTopics,
          {
            topic_id,
            topic_name,
            mcq_no_of_questions,
            fillup_no_of_questions,
            short_no_of_questions,
            long_no_of_questions
          },
        ];
      }
    });
  };

  const addTopic = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "topic_name": topic_name,
      "subject_id": subjectId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/add/topic`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          handleClose();
          // toast.success(result.message);
          setTopic_name("");
          onTopicAdded();
        } else if (result.status === "VAL_ERR") {
          toast.error(result.Backend_Error);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch(error => console.log('error', error));
  }

  const totalSubjectSelected = Object.values(selectedSubjects).filter(
    (selected) => selected
  ).length;

  useEffect(() => {
    if (onTopics) {
      onTopics(selectedSubjects || null)
    }
  }, [selectedSubjects, onTopics])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Toaster />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='row align-items-center'>
              <div className='col-6'>
                <div style={{ lineHeight: "0px" }}>
                  <h1 className='createPaperHeading' >Select Topics</h1>
                  <p style={{ fontSize: '12px' }} ><span style={{ color: "#FF2525" }}>{totalSubjectSelected}</span> Subjects Selected</p>
                </div>
              </div>
              <div className='col-6'>
                <img src={plus} alt="plus" onClick={handleShow} style={{ float: "right", border: "2px solid", fontSize: "2rem", borderRadius: "5px" }} className='p-1' />
                <FiFilter className='p-1' style={{ float: "right", border: "2px solid", fontSize: "2rem", borderRadius: "5px", marginRight: "1rem" }} />
              </div>
            </div>
            {
              loading ? <Loader show={loading} /> :
                <div className='my-2' style={{ height: "43vh", overflowY: "auto" }} >
                  {
                    showTopic.length > 0 ?
                      showTopic.map((value, index) => (
                        <div
                          key={index}
                          className='card p-3 mt-3'
                          style={{
                            border: selectedSubjects.some((topic) => topic.topic_id === value.topic_id)
                              ? '2px solid #00BA4A'
                              : '2px solid #DFDFDF',
                          }}
                          onClick={() => handleSubjectClick(value.topic_id, value.topic_name,value.mcq_no_of_questions,value.fillup_no_of_questions,value.short_no_of_questions,value.long_no_of_questions)}
                        >
                          <div className='d-flex align-items-center justify-content-between'>
                            <h5 style={{ color: selectedSubjects.some((topic) => topic.topic_id === value.topic_id) ? '#00BA4A' : '#000' }}>
                              {value.topic_name}
                            </h5>
                            {selectedSubjects.some((topic) => topic.topic_id === value.topic_id) ? <AiOutlineCheck /> : <AiOutlinePlus />}
                          </div>
                        </div>
                      )) : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>No Topic in this Subject</h4></>
                  }
                </div>
            }
          </div>
        </div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="bottom"
          style={{ height: "60%" }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title
              className="d-flex justify-content-center mx-auto"
              onClick={handleClose}
            >
              <div
                style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
              ></div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <h1
              className="text-center"
              style={{
                color: "#000",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Add Topic
            </h1>
            <div className="d-flex flex-column justify-content-between h-75 mt-3">
              <div className="d-flex flex-column gap-3">
                <input
                  value={topic_name}
                  onChange={(e) => setTopic_name(e.target.value)}
                  type="text"
                  className="w-100 p-2"
                  placeholder="Topic Name"
                  style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                />
              </div>
              <div>
                <button
                  onClick={() => addTopic()}
                  className="w-100 py-2 border-0"
                  style={{
                    background: "#EDF7FF",
                    borderRadius: "5px",
                    color: "#3774A5",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  )
}

export default SelectTopic
