import React from "react";
import { useNavigate } from "react-router-dom";
import backButton from "../assets/backButton.svg";
import sort from "../assets/sort.svg";
import plus from "../assets/plus.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { API_URL } from "../../env";

const Course = ({ onCourseAdded, course_sort_by_A_Z, select }) => {
  const Navigate = useNavigate();
  const [showboard, setShowboard] = useState([]);
  const [course_name, setCourse_name] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/show/all/board`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setShowboard(result.allBoards);
        } else if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch((error) => console.log("error", error));
  };

  const addCourse = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("question_bank_token")}`
    );

    var raw = JSON.stringify({
      course_name: course_name,
      board_id: selectedOption,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/add/course`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          handleClose();
          // toast.success(result.message)
          setCourse_name("")
          setSelectedOption(null)
          onCourseAdded();
        } else if (result.status == "VAL_ERR") {
          toast.error(result.Backend_Error)
        } else {
          console.log(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div>
      <div className="container h-100">
        <div className="row">
          <Toaster position="top-center" reverseOrder={false} />
          <div className="col-lg-12 col-sm-12 mt-2">
            <img
              onClick={() => Navigate(-1)}
              src={backButton}
              alt="back"
            />
            <div className="row align-items-center mt-4">
              <div className="col-6">
                <h1
                  style={{
                    fontFamily: "Archivo",
                    color: "#000",
                    fontWeight: 500,
                    fontStyle: "normal",
                    fontSize: "1.5rem",
                  }}
                >
                  Course
                </h1>
              </div>
              <div className="col-6 text-end d-flex justify-content-end align-items-center gap-3">
                <div
                  className="p-1"
                  style={{ border: "1px solid #000", borderRadius: "5px" }}
                >
                  {
                    select == 1 ?
                      <img src={sort} alt="sort" onClick={() => course_sort_by_A_Z()} /> :
                      <img src={sort} alt="sort"  />
                  }
                </div>
                <div
                  className="p-1 px-2"
                  style={{ border: "1px solid #000", borderRadius: "5px" }}
                >
                  <img src={plus} alt="plus" onClick={() => { handleShow(); options(); }} />
                </div>
              </div>
            </div>
            <div
              className="col-12 mt-3 example"
              style={{ height: "", overflowY: "auto" }}
            ></div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "60%" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            className="d-flex justify-content-center mx-auto"
            onClick={handleClose}
          >
            <div
              style={{ width: "50px", height: "2px", background: "#E1E1E1" }}
            ></div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
          <h1
            className="text-center"
            style={{
              color: "#000",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Add Courses
          </h1>
          <div className="d-flex flex-column justify-content-between mt-3 " style={{height:"85%"}} >
            <div className="d-flex flex-column gap-3">
              <input
                type="text"
                className="w-100 p-2"
                placeholder="Course Name"
                style={{ border: "1px solid #E5E5E5", borderRadius: "5px" }}
                value={course_name}
                onChange={(e) => setCourse_name(e.target.value)}
              />
              <Select
                className="mt-2"
                styles={{ border: "1px solid #E5E5E5" }}
                defaultValue={selectedOption}
                placeholder="Medium/Board"
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption.value);
                }}
                options={showboard.map((board) => ({
                  value: board.id,
                  label: board.board_name,
                }))}
              />
            </div>

            <div>
              <button
                onClick={addCourse}
                className="w-100 py-2 border-0"
                style={{
                  background: "#EDF7FF",
                  borderRadius: "5px",
                  color: "#3774A5",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Add Courses
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Course;
