import React, { useEffect, useState } from 'react'
import Select from 'react-select';

const SelectCourse = ({ onCourse, showcourse }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (onCourse) {
            onCourse(selectedOption?.value || null)
        }
    }, [selectedOption, onCourse])

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h1 className='createPaperHeading mt-3' >Select Course</h1>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={showcourse.map((res) => ({
                                value: res.course_id,
                                label: res.course_name
                            }))}
                            styles={{ overflowY: "scroll", }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectCourse
