import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import backButton from '../assets/backButton.svg';
import { API_URL } from '../../env';
import Offcanvas from "react-bootstrap/Offcanvas";
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../common/Loader'

const Profile = () => {

  const Navigate = useNavigate();
  const [def, setDef] = useState("");
  const [name, setName] = useState("");
  const inputFileRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [banner, setBanner] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const handleProfileChange = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setDef(fileUrl);
      setBanner(fileUrl);
    } else {
      setBanner(def);
    }
  };

  const showprofile = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsloading(true)
    fetch(`${API_URL}/show/profile`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsloading(false)
        setDef(result.user_profile.baseUrl);
        setName(result.user_profile.username)
        setBanner(result.user_profile.profile_picture);
        if (result.status == 111) {
          localStorage.removeItem("question_bank_token");
          Navigate('/')
        }
      })
      .catch(error => console.log('error', error));
  }

  const editProfile = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var formdata = new FormData();
    formdata.append("username", name);
    formdata.append("profile_picture", banner);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}/edit/profile`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          showprofile();
          // toast.success(result.message);
        } else if (result.status == "VAL_ERR") {
          toast.error(result.Backend_Error);
        } else {
          console.log(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    showprofile();
  }, [])

  // Function to handle the save button click
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        style={{ height: "60%" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            className="d-flex justify-content-center mx-auto"
            onClick={handleClose}
          >
            <div
              style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
            ></div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1
            className="text-center"
            style={{
              color: "#000",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Edit Name
          </h1>
          <div className="d-flex flex-column justify-content-between h-75 mt-3">
            <div className="d-flex flex-column gap-3">
              <input
                defaultValue={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
                className="w-100 p-2"
                style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                onClick={() => handleClose()}
                className="w-50 py-2 border-0"
                style={{
                  background: "#EDF7FF",
                  borderRadius: "5px",
                  color: "#3774A5",
                  fontSize: "0.875rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div>
        <Toaster />
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                isLoading ? <Loader /> :
                  <>
                    <div className="d-flex mt-2" style={{ justifyContent: 'space-between' }}>

                      <img onClick={() => Navigate('/Home')} src={backButton} alt="back" />
                      <button className='btn btn-success' onClick={() => editProfile()} style={{ fontSize: '1.3rem', }}>
                        Save
                      </button>
                    </div>
                    <div>
                      <div
                        style={{
                          height: '30vh',
                          border: 'red',
                          width: '100%',
                          display: 'flex',
                          flexDirection: "column",
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        className='mt-3'
                      >
                        <div style={{ width: '150px', height: '150px', overflow: 'hidden', }}>
                          <img
                            src={def}
                            alt="dsg"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '50%',
                            }}
                          />
                        </div>

                        <button className='btn btn-primary mt-3' onClick={handleProfileChange} style={{ color: "" }}>Change profile</button>
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          ref={inputFileRef}
                          onChange={(e) => {
                            setBanner(e.target.files[0]);
                            setDef(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                          accept="png,jpeg"
                        />
                      </div>
                    </div>

                    <div className="d-flex" style={{ justifyContent: 'space-between', marginTop: '2rem' }}>
                      <p style={{ fontSize: "20px" }}>Name :</p>
                      <button className='btn btn-primary' >
                        <MdEdit style={{ fontSize: "20px" }} onClick={handleShow} />
                      </button>
                    </div>
                    <h1 style={{ textTransform: "capitalize" }}>{name}</h1>
                  </>
              }


              {/* Save button */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
