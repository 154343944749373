import React, { useState, useEffect } from "react";
import filter from "../assets/filter.svg";
import sort from "../assets/sort.svg";
import HistoryCard from "./HistoryCard";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../env";

const HistoryData = () => {
  const { status } = useParams();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [questionPaper, setQuestionPaper] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFilterClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const cardData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsLoading(true);
    fetch(`${API_URL}/show/question/paper`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false);
        if (result.status == 1) {
          setQuestionPaper(result.question_paper_details);
        }
      })
      .catch(error => console.log('error', error));
  }

  const question_paper_by_date = async() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "date": selectedDate
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`${API_URL}/search/questions/by/date`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false);
        if (result.status == 1) {
          setQuestionPaper(result.question_paper_details);
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    cardData()
  }, [])

  return (
    <>
      <div className="container h-100">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="row align-items-center">
              <div className="col-6">
                {
                  status == "MyPaper" ?
                    <h1 style={{ fontFamily: "Archivo", color: "#000", fontWeight: 500, fontStyle: "normal", fontSize: "1.5rem" }}>Previous Papers</h1> :
                    <h1 style={{ fontFamily: "Archivo", color: "#000", fontWeight: 500, fontStyle: "normal", fontSize: "1.5rem" }}>Paper History</h1>
                }
              </div>
              <div className="col-6 text-end d-flex justify-content-end align-items-center gap-3" >
                <div style={{ position: 'relative' }}>
                  <button
                    className="p-1"
                    style={{ border: '1px solid #000', borderRadius: '5px' }}
                    onClick={handleFilterClick}
                  >
                    <img src={filter} alt="filter" />
                  </button>
                </div>

                <button className="p-1" style={{ border: "1px solid #000", borderRadius: "5px" }}>
                  <img src={sort} alt="sort" />
                </button>
              </div>
              <div className='mt-4 '>
                {showDatePicker && (
                  <>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) =>
                        setSelectedDate(date)
                      }
                      dateFormat="yyyy-MM-dd"
                    />
                    <button className="btn ms-2" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff", fontSize: "0.85rem", fontStyle: "normal", fontWeight: 400, backgroundColor: "rgba(85,101,206,255)", }} onClick={() => question_paper_by_date()}>Apply</button>
                    <button className="btn ms-2" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff", fontSize: "0.85rem", fontStyle: "normal", fontWeight: 400, backgroundColor: "#AD1111" }} onClick={() => { cardData(); setSelectedDate('') }}>Remove</button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 example" style={{ height: "68vh", overflowY: "auto" }} >
            <HistoryCard questionPaper={questionPaper} isLoading={isLoading} cardData={cardData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryData;