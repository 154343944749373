
import React,{useEffect, useState} from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { API_URL } from '../../env';
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../common/Loader';

const FinalSemExam = ({ selectedTopics, add, sections,mcqCompulsory,longCompulsory,shortCompulsory,fillUpCompulsory }) => {
  const [section, setSection] = useState([]);
  const sectionIdsString = localStorage.getItem("sections");
const sectionIdsArray = sectionIdsString ? sectionIdsString.split(',') : [];

const numberOfSections = sectionIdsArray.length;
  
  const [isLoading, setIsloading] = useState(false);
  localStorage.setItem("paper-name",add.que_paper_name);
  localStorage.setItem("total-questions",parseInt(mcqCompulsory ) + parseInt(shortCompulsory) + parseInt(longCompulsory) + parseInt(fillUpCompulsory));
  localStorage.setItem("total-marks",parseInt(add.long_mpq*longCompulsory) + parseInt(add.short_mpq*shortCompulsory) + parseInt(add.fillup_mpq*fillUpCompulsory) + parseInt(add.mcq_mpq*mcqCompulsory) );
  localStorage.setItem("total-sections",numberOfSections);

  const data = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

    var raw = JSON.stringify({
      "section_ids": [localStorage.getItem("sections")]
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsloading(true);
    fetch(`${API_URL}/show/section/byid`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsloading(false)
        if (result.status == 1) {
          setSection(result.section_details);
          // showSection(section);
        }
      })
      .catch(error => console.log('error', error));
  }

  const options = [
    { type: 'Multiple choice Questions', count: mcqCompulsory },
    { type: 'Short Answer', count: shortCompulsory },
    { type: 'Long Answer', count: longCompulsory },
    { type: 'Fill in the blank', count: fillUpCompulsory },
  ];
  const info = [
    {
      title: 'Total Questions', value: parseInt(mcqCompulsory ) + parseInt(shortCompulsory) + parseInt(longCompulsory) + parseInt(fillUpCompulsory)
    },
    { title: 'Total Marks', value: parseInt(add.long_mpq*longCompulsory) + parseInt(add.short_mpq*shortCompulsory) + parseInt(add.fillup_mpq*fillUpCompulsory) + parseInt(add.mcq_mpq*mcqCompulsory) },
  ];

  useEffect(() => {
    data();
  }, [])
  

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div>
              <h1 className='head'>Preview</h1>
              <p style={{ color: "#565656" }}>Name of paper</p>
              <h1 style={{ fontSize: "1.2rem", fontWeight: "700", paddingBottom: "9px" }}>{add.que_paper_name}</h1>
            </div>
            <hr style={{ border: "1px solid #565656", width: "100%" }}></hr>
          </div>
          <div className='col-12'>
            <div className='example' style={{ overflowY: "auto", height: "39vh" }}>
              <div style={{ height: "auto", width: "100%", marginTop: "1rem" }}>
                <p style={{ color: "#565656" }}>Topics & Composition</p>
                {
                  selectedTopics.map((res) => {
                    return (
                      <div className="dropdown">
                        <button
                          style={{
                            width: "95%",
                            borderRadius: "5px",
                            border: " 1px solid #E1E1E1",
                            backgroundColor: "white",
                            color: "black",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginLeft: "8px",
                            padding: "7px 9px",
                            marginTop: "1rem"
                          }}
                          className="btn-secondary"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {res.topic_name}
                          {/* <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ fontSize: "14px", verticalAlign: "middle", marginBottom: "0rem", color: "red", marginRight: "1rem" }}>{parseInt(mcqCompulsory) + parseInt(shortCompulsory) + parseInt(longCompulsory) + parseInt(fillUpCompulsory)}</p>
                            <IoIosArrowDown style={{}} />
                          </div> */}
                        </button>
                        {/* <ul style={{ width: "95%" }} className="dropdown-menu">
                    <div style={{ display: "flex", justifyContent: 'space-between', fontSize: "14px" }}>
                      <div style={{ color: "#565656" }}>
                        {options.map((option, index) => (
                          <li key={index}>
                            <a className="dropdown-item" href="#">
                              {option.type}
                            </a>
                          </li>
                        ))}
                      </div>
                      <div>
                        {options.map((option, index) => (
                          <li key={index}>
                            <a className="dropdown-item" href="#" style={{ color: "red", fontSize: "13px" }}>
                              {option.count}
                            </a>
                          </li>
                        ))}
                      </div>
                    </div>
                  </ul> */}
                      </div>
                    )
                  })
                }

                <div>
                  {info.map((item, index) => (
                    <div key={index} className='px-2 d-flex justify-content-between align-items-center mt-3'>
                      <h2 className='tq' style={{ color: "#565656" }}>{item.title}</h2>
                      <p className='tc' style={{ color: "#565656" }}>{item.value}</p>
                    </div>
                  ))}
                </div>

                <hr style={{ border: "1px solid #565656", width: "100%" }}></hr>
                <hr style={{ border: "#565656", width: "100%", marginBottom: "1rem" }}></hr>
                {/* <p className='px-2' style={{ color: "#565656", }}>Sets(4)</p> */}

              </div>
              <div className='container-fluid' >
                <div className='row px-0'>
                  <div className='px-0'>
                    {
              isLoading ? <Loader show={isLoading} /> :
                  <div className='col-12' >
                    {
                      section.length > 0 ?
                        section.map((res, index) => {
                          return (
                            <div className='card mt-2' >
                              <div className='card-body'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <h4>{res.section_name}</h4>
                                  {/* <h6>{res.marks}</h6> */}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  {
                                    res.questions_type == "fillup" ?
                                      <h6>Fill in the Blanks</h6> :
                                      res.questions_type == "long" ?
                                        <h6 >Long Questions</h6> :
                                        res.questions_type == "short" ?
                                          <h6 >Short Questions</h6> :
                                          <h6 >Multiple Choice Questions</h6>
                                  }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  {/* <h6 style={{ color: '#565656' }}>{res.chemistry}</h6> */}
                                  {/* <h6 style={{ color: '#565656' }}>{res.a12}</h6> */}
                                </div>
                              </div>
                            </div>
                          )
                        }) : <></>
                    }
                  </div>
            }
                  </div>
                </div>
              </div>

              <hr style={{ border: "#565656", width: "", marginBottom: "1rem" }}></hr>
              {/* <p style={{ color: "#565656" }}>Sets (4)</p> */}
              {/* <div className='mt-2'>
                {setData.map((set, index) => (
                  <div key={index} className='sets p-3 mt-3'>
                    {set}
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinalSemExam
