import React, { useEffect, useState } from 'react'
import Select from 'react-select';

const SelectBoard = ({ showboard, onBoardSelect }) => {

    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (onBoardSelect) {
            onBoardSelect(selectedOption?.value || null);
        }
    }, [selectedOption, onBoardSelect]);

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h1 className='createPaperHeading mt-3' >Select Board</h1>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={showboard.map((selectBoard) => ({
                                value: selectBoard.id,
                                label: selectBoard.board_name
                            }))}
                            styles={{ overflowY: "scroll", }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectBoard
