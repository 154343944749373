import React from 'react'
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../env';
import { useEffect } from 'react';
import { Loader } from '../common/Loader'
import toast, { Toaster } from 'react-hot-toast';

const TabsBlanks = ({ status }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [fillup, setFillup] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState("");
    const [selected, setSelected] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (index) => {
        setSelected(fillup[index]);
        setShow2(true)
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const uploadExcel = async () => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);
    
        let formData = new FormData();
        formData.append("type", "fillup");
        formData.append("topic_id", String(id));
        formData.append("excel", selectedFile);
    
        await fetch(`${API_URL}/add/bulk/question/type`, {
          method: "POST",
          headers: myHeaders,
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status == 1) {
              toast.success(result.message)
              handleClose1()

            }else if(result.status=="FILE_ERR"){
                toast.error(result.Backend_Error)
            }
            else if(result.status==111){
            localStorage.removeItem("question_bank_token");
            navigate('/')
             }
            else{
              toast.error(result.Backend_Error)
            }
          })
    
          .catch((error) => {
            toast.error(error)
            console.log("error", error)});
      };


      const getExcel = async () => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);
    

    
        await fetch(`${API_URL}/download/excel/format`, {
          method: "GET",
          headers: myHeaders,
        })
          .then((response) => response.blob())
          .then((result) => {
              const url = URL.createObjectURL(result);
              setDownloadUrl(url);
          })
    
          .catch((error) => console.log("error", error));

      };

    const blanksData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "topic_id": id,
            "type": "fillup"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setIsLoading(true);
        fetch(`${API_URL}/show/questions/bytopic`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setIsLoading(false);
                setFillup(result.questions);
                if (result.status == 111) {
                    localStorage.removeItem("question_bank_token");
                    navigate('/')
                }
            })
            .catch(error => console.log('error', error));
    }

    const allQuestion = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "type": "fillup"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setIsLoading(true)
        fetch(`${API_URL}/show/questions`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setIsLoading(false)
                if (result.status == 1) {
                    setFillup(result.question_details);
                } else if (result.status == 111) {
                    localStorage.removeItem("question_bank_token");
                    navigate('/')
                }
            })
            .catch(error => console.log('error', error));
    }

    const addFill = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "topic_id": id,
            "question": question,
            "type": "fillup"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/add/question`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    // toast.success(result.message)
                    handleClose();
                    blanksData();
                    setQuestion("");
                } else if (result.status == "VAL_ERR") {
                    toast.error(result.Backend_Error);
                } else {
                    console.log(result.error)
                }
            })
            .catch(error => console.log('error', error));
    }

    const edit = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "question_id": selected?.id,
            "question": question,
            "type": "fillup"
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/edit/questions/byid`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    // toast.success(result.message)
                    handleClose2();
                    blanksData();
                    setQuestion("");
                } else if (result.status == "VAL_ERR") {
                    toast.error(result.Backend_Error);
                } else {
                    console.log(result.error)
                }
            })
            .catch(error => console.log('error', error));
    }

    const Delete = async (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("question_bank_token")}`);

        var raw = JSON.stringify({
            "question_id": id,
            "type": "fillup"
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/delete/questions/byid`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    // toast.success(result.message)
                    blanksData();
                } else if (result.status == "VAL_ERR") {
                    toast.error(result.Backend_Error);
                } else {
                    console.log(result.error)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getExcel()
        if (status == "Question_Bank") {
            allQuestion();
        } else {
            blanksData();
        }
    }, [])

    return (
        <>
            <div className="ms-auto" style={{ display: "flex", justifyContent: "end" }}>
                <Toaster />
                {
                    status == "Question_Bank" ? "" :
                    <>
                    <div className='d-flex gap-2'>

                    <button className="btn px-3 ms-auto" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff",      backgroundColor: "rgba(85,101,206,255)", }} onClick={handleShow}>
                            Add F/B
                        </button>
                        <button className="btn px-3 ms-auto" style={{ border: "1px solid #fff", borderRadius: "5px", color: "#fff",      backgroundColor: "rgba(85,101,206,255)", }} onClick={handleShow1}>
                            Add in Bulk
                        </button>

                        </div>
                        </>
                }
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="bottom"
                    style={{ height: "30%" }}
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title
                            className="d-flex justify-content-center mx-auto"
                            onClick={handleClose}
                        >
                            <div
                                style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                            ></div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h1
                            className="text-center"
                            style={{
                                color: "#000",
                                fontSize: "1.25rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                            }}
                        >
                            Add Question
                        </h1>
                        <div className="d-flex flex-column justify-content-between h-75" >
                            <div className="d-flex">
                                <input
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                    type="text"
                                    className="w-100 p-2"
                                    placeholder="Question"
                                    style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                />
                            </div>

                            <div>
                                <button
                                    onClick={() => addFill()}
                                    className="w-100 py-2 border-0"
                                    style={{
                                        background: "#EDF7FF",
                                        borderRadius: "5px",
                                        color: "#3774A5",
                                        fontSize: "0.875rem",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            {
                isLoading ? <Loader show={isLoading} /> : <>
                    {
                        fillup.length > 0 ?
                            fillup.map((res, index) => {
                                return (
                                    <div className='card p-2 border-0 mt-3' >
                                        <p> Q{index + 1}. {res.question}</p>
                                        {
                                            status == "Question_Bank" ?
                                                <>
                                                    <p
                                                        style={{
                                                            margin: "0%",
                                                            color: "#4EC53B",
                                                            fontFamily: "Archivo",
                                                            fontSize: "0.95rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            background: "#ECFFE9",
                                                            borderRadius: "10px",
                                                            width: "max-content",
                                                            textTransform: "capitalize"
                                                        }}
                                                        className="my-2 mb-2 p-1 px-2"
                                                    >
                                                        Subject : {res.subject_name}
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: "0%",
                                                            color: "#4EC53B",
                                                            fontFamily: "Archivo",
                                                            fontSize: "0.95rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            background: "#ECFFE9",
                                                            borderRadius: "10px",
                                                            width: "max-content",
                                                        }}
                                                        className="my-2 mb-2 p-1 px-2"
                                                    >
                                                        Topic : {res.topic_name}
                                                    </p>
                                                </> : ""
                                        }
                                        {
                                            status == "Question_Bank" ? "" :
                                                <div className='row pb-2 ms-auto'>
                                                    <div className='col-6' >
                                                        <button className='w-100 py-2 btn' style={{ backgroundColor: "black", color: "white" }} onClick={() => handleShow2(index)}><FaEdit /></button>
                                                    </div>
                                                    <div className='col-6' >
                                                        <button className='w-100 py-2 btn' style={{ backgroundColor: "#AD1111", color: "white" }} onClick={() => Delete(res.id)} ><MdDelete /></button>
                                                    </div>
                                                </div>
                                        }
                                        <hr />
                                        <Offcanvas
                                            show={show2}
                                            onHide={handleClose2}
                                            placement="bottom"
                                            style={{ height: "30%" }}
                                        >
                                            <Offcanvas.Header>
                                                <Offcanvas.Title
                                                    className="d-flex justify-content-center mx-auto"
                                                    onClick={handleClose2}
                                                >
                                                    <div
                                                        style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                                                    ></div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <h1
                                                    className="text-center"
                                                    style={{
                                                        color: "#000",
                                                        fontSize: "1.25rem",
                                                        fontStyle: "normal",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Edit Question
                                                </h1>
                                                <div className="d-flex flex-column justify-content-between h-75" >
                                                    <div className="d-flex flex-column gap-3">
                                                        <input
                                                            type="text"
                                                            defaultValue={selected?.question || ""}
                                                            className="w-100 p-2"
                                                            onChange={(e) => setQuestion(e.target.value)}
                                                            style={{ border: "1px solid #E5E5E5", borderRadius: "5px", }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => edit()}
                                                            className="w-100 py-2 border-0"
                                                            style={{
                                                                background: "#EDF7FF",
                                                                borderRadius: "5px",
                                                                color: "#3774A5",
                                                                fontSize: "0.875rem",
                                                                fontStyle: "normal",
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </div>
                                )
                            }) : <><h4 className='text-center mt-5' style={{ color: "red", fontWeight: "600" }}>No Fill in the Blanks</h4></>
                    }
                </>
            }
            {/* for adding in bulk */}
<Offcanvas
                    show={show1}
                    onHide={handleClose1}
                    placement="bottom"
                    style={{ height: "25%" }}
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title
                            className="d-flex justify-content-center mx-auto"
                            onClick={handleClose1}
                        >
                            <div
                                style={{ width: "50px", height: "2px", background: "#E1E1E1", }}
                            ></div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>

                    <div>
                        <div className='d-flex justify-content-between'>
      <div><h2>Upload Excel File</h2></div>
      <div>
      <a
                                                                href={downloadUrl}
                                                                download="F/B_Que_Format.xlsx"
                                                                className="w-100 p-2 border-0 "
                                                                style={{
                                                                    textDecoration:"none",
                                                                    background: "#EDF7FF",
                                                                    borderRadius: "5px",
                                                                    color: "#3774A5",
                                                                    fontSize: "0.875rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Download Format
                                                            </a>
        </div>
      </div>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={(event)=>{    
            setSelectedFile(event.target.files[0]);}}
      />

                                                            <button
                                                                onClick={uploadExcel}
                                                                className="w-100 py-2 border-0 my-3"

                                                                style={{
                                                                    background: "#EDF7FF",
                                                                    borderRadius: "5px",
                                                                    color: "#3774A5",
                                                                    fontSize: "0.875rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Upload
                                                            </button>
                                                        </div>

                       
                    </Offcanvas.Body>
                </Offcanvas>
        </>
        
    )
}

export default TabsBlanks
